import { CompletionRule } from 'app/library/course-offering/enums/completion-rule.enum';
import { Module } from './module.model';

export class CompletionRuleCommand {
    completion_rule: CompletionRule;
    required_sections_for_completion: number[];

    constructor(tempModule: Module, requiredSectionForCompletion?: string) {
        this.completion_rule = tempModule.completionRule;
        if (requiredSectionForCompletion) {
            this.required_sections_for_completion = [parseInt(requiredSectionForCompletion)];
        }
    }
}
