import { IUserToDosStatisticsQuery } from '../interfaces/user-to-dos-statistics.query.interface';

export class UserToDosStatistics {
    totalCountOfOpenToDos: number;
    totalCountOfCompletedToDos: number;
    totalCountOfToDosThatRequireAction: number;

    constructor(query: IUserToDosStatisticsQuery) {
        this.totalCountOfOpenToDos = query.total_count_of_open_to_dos;
        this.totalCountOfCompletedToDos = query.total_count_of_completed_to_dos;
        this.totalCountOfToDosThatRequireAction = query.total_count_of_to_dos_that_require_action;
    }
}
