import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MaxBrainAudienceUsersModule } from 'app/library/audience-users/audience-users.module';
import { AudienceModule } from 'app/library/audience/audience.module';
import { ExperienceUsersService } from 'app/library/experience-users/services/experience-users.service';
import { MaxBrainSharedModule } from 'app/projects/shared/src/lib/shared.module';
import { ExperienceService } from '../../experience/services/experience.service';
import { UserCreateFormDialogComponent } from './user-create-form-dialog/user-create-form-dialog.component';

@NgModule({
    imports: [
        MatIconModule,
        MatToolbarModule,
        MatSelectModule,
        MatStepperModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        MaxBrainSharedModule,
        MatProgressSpinnerModule,
        AudienceModule,
        MaxBrainAudienceUsersModule,
        MatTableModule,
    ],
    declarations: [UserCreateFormDialogComponent],
    entryComponents: [UserCreateFormDialogComponent],
    exports: [UserCreateFormDialogComponent],
    providers: [ExperienceService, ExperienceUsersService],
})
export class UserCreateModule {}
