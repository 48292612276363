import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { MaxBrainUtils } from 'app/projects/core/src/lib/utils';
import { IEntityState } from 'app/projects/entity/src/lib/interfaces/entity.state.interface';
import { EntityState } from 'app/projects/entity/src/lib/models/entity.state';
import {
    CreateAudience,
    CreateAudienceFailure,
    CreateAudienceSuccess,
    DeleteAudience,
    DeleteAudienceFailure,
    DeleteAudienceSuccess,
    FetchAudience,
    FetchAudienceFailure,
    FetchAudienceSuccess,
    FetchAudienceWidgets,
    FetchAudienceWidgetsFailure,
    FetchAudienceWidgetsSuccess,
    FetchAudiences,
    FetchAudiencesFailure,
    FetchAudiencesSuccess,
    MoveToAudience,
    SetAudience,
    SetAudiences,
    UnsetAudience,
    UpdateAudience,
    UpdateAudienceFailure,
    UpdateAudienceSuccess,
} from '../actions';
import { AddAudiences } from '../actions/add-entities.action';
import { AudienceApiService } from '../services/audience.api-service';
import { MaxBrainAudience } from './audience.model';
import { AudienceStateModel } from './audience.state-model';

@State<AudienceStateModel>({
    name: 'audience',
    defaults: new AudienceStateModel(),
})
@Injectable()
export class AudienceState extends EntityState<MaxBrainAudience> implements IEntityState<MaxBrainAudience> {
    static sortProperties: string[] = ['name'];

    @Selector()
    static getEntity(state: AudienceStateModel, id: string = state.item): MaxBrainAudience {
        if (!state.map) {
            return null;
        }

        return state.map.get(id) || null;
    }

    @Selector()
    static getEntities(state: AudienceStateModel): MaxBrainAudience[] {
        if (!state.map) {
            return null;
        }

        const toReturn = Array.from(state.map.values());

        toReturn.sort(MaxBrainUtils.sortCompareFn(AudienceState.sortProperties));

        return toReturn;
    }

    constructor(private _audienceService: AudienceApiService) {
        super(_audienceService, 0);

        this._propstoSearch = MaxBrainAudience.props_to_search;
    }

    @Action(AddAudiences)
    addEntities(ctx: StateContext<AudienceStateModel>, action: AddAudiences): void {
        this._addEntities(ctx, action);
    }

    @Action(SetAudience)
    setEntity(ctx: StateContext<AudienceStateModel>, action: SetAudience): void {
        this._setEntity(ctx, action, AddAudiences);
    }

    @Action(UnsetAudience)
    unsetEntity(ctx: StateContext<AudienceStateModel>): void {
        this._unsetEntity(ctx);
    }

    @Action(CreateAudience)
    createEntity(ctx: StateContext<AudienceStateModel>, action: CreateAudience): void {
        this._createEntity(ctx, action, CreateAudienceSuccess, CreateAudienceFailure, AddAudiences).then(() => {});
    }

    @Action(UpdateAudience)
    updateEntity(ctx: StateContext<AudienceStateModel>, action: UpdateAudience): void {
        this._updateEntity(ctx, action, UpdateAudienceSuccess, UpdateAudienceFailure, AddAudiences).then(() => {});
    }

    @Action(MoveToAudience)
    moveToAudience(ctx: StateContext<AudienceStateModel>, action: MoveToAudience): void {
        this._audienceService.moveTo(action.payload.current, action.payload.target).subscribe(
            (entity) => {
                ctx.dispatch([new UpdateAudienceSuccess(entity), new AddAudiences([entity])]);
            },
            (response: HttpErrorResponse) => {
                ctx.dispatch(new UpdateAudienceFailure({ entityId: action.payload.current.id, response }));
            }
        );
    }

    @Action(DeleteAudience)
    deleteEntity(ctx: StateContext<AudienceStateModel>, action: DeleteAudience): void {
        this._deleteEntity(ctx, action, DeleteAudienceSuccess, DeleteAudienceFailure).then(() => {});
    }

    @Action(FetchAudience)
    fetchEntity(ctx: StateContext<AudienceStateModel>, action: FetchAudience): void {
        this._fetchEntity(ctx, action, FetchAudienceSuccess, FetchAudienceFailure, AddAudiences, SetAudience).then(() => {});
    }

    @Action(FetchAudienceSuccess)
    fetchEntitySuccess(ctx: StateContext<AudienceStateModel>, action: FetchAudienceSuccess): void {
        this._fetchEntitySuccess(ctx, action);
    }

    @Action(FetchAudienceFailure)
    fetchEntityFailure(ctx: StateContext<AudienceStateModel>, action: FetchAudienceFailure): void {
        this._fetchEntityFailure(ctx, action);
    }

    @Action(SetAudiences)
    setEntities(ctx: StateContext<AudienceStateModel>, action: SetAudiences): void {
        this._setEntities(ctx, action);
    }

    @Action(FetchAudiences)
    fetchEntities(ctx: StateContext<AudienceStateModel>, action: FetchAudiences): void {
        this._fetchEntities(ctx, FetchAudiencesSuccess, FetchAudiencesFailure, SetAudiences, this._audienceService.getAll(action.payload.search)).then(() => {});
    }

    @Action(FetchAudiencesSuccess)
    fetchEntitiesSuccess(ctx: StateContext<AudienceStateModel>): void {
        this._fetchEntitiesSuccess(ctx);
    }

    @Action(FetchAudiencesFailure)
    fetchEntitiesFailure(ctx: StateContext<AudienceStateModel>): void {
        this._fetchEntitiesFailure(ctx);
    }

    @Action(FetchAudienceWidgets)
    fetchArticleAuthors(ctx: StateContext<AudienceStateModel>, action: FetchAudienceWidgets): void {
        this._audienceService.fetchAudienceWidgets().subscribe(
            (entities) => {
                ctx.dispatch(new FetchAudienceWidgetsSuccess(entities));
            },
            (response: HttpErrorResponse) => {
                ctx.dispatch(new FetchAudienceWidgetsFailure());
            }
        );
    }
}
