import { HttpErrorResponse } from '@angular/common/http';
import { FinalGradeMetrics } from 'app/library/final-grade/models/final-grade-metric.model';
import { ParticipantRegistrationMetric } from 'app/library/participant-registration-metric/models/participant-registration-metric.model';
import { RelevantExecution } from 'app/library/program-item/models/relevant-execution.model';
import { ScheduledJob } from 'app/library/scheduler/models/scheduled-job.model';
import { ImportStatus } from 'app/library/scorm/models/import-status.model';
import { ICreateEntityFailureActionPayload } from 'app/projects/entity/src/lib/interfaces/create-entity-failure-payload.interface';
import { IFetchEntityFailureActionPayload } from 'app/projects/entity/src/lib/interfaces/fetch-entity-failure-payload.interface';
import { IUpdateEntityFailureActionPayload } from 'app/projects/entity/src/lib/interfaces/update-entity-failure-payload.interface';
import { ICopyModuleToNewActionPayload } from '../interfaces/copy-module-to-new.action-payload.interface';
import { INotifyAllParticipantsActionPayload } from '../interfaces/notfiy-all.action.interface';
import { INotifySelectedParticipantsActionPayload } from '../interfaces/notfiy-selected.action.interface';
import { CourseStructureModuleBreadcrumb } from '../models/course-structure-module-breadcrumb.model';
import { ELearningModule } from '../models/eLearning-module.model';
import { Module } from '../models/module.model';
import { TenantAdminModuleStats } from '../models/tenant-admin-module-stats.model';

export class SetModule {
    static readonly type = '[Module] SetModule';

    constructor(public payload: Module) {}
}

export class UnsetModule {
    static readonly type = '[Module] UnsetModule';
}

export class FetchModule {
    static readonly type = '[Module] FetchModule';

    constructor(public payload: string) {}
}

export class FetchModuleSuccess {
    static readonly type = '[Module] FetchModuleSuccess';

    constructor(public payload: Module) {}
}

export class FetchModuleFailure {
    static readonly type = '[Module] FetchModuleFailure';

    constructor(public payload: IFetchEntityFailureActionPayload) {}
}

export class CreateModule {
    static readonly type = '[Module] CreateModule';

    constructor(public payload: Module) {}
}

export class CreateModuleSuccess {
    static readonly type = '[Module] CreateModuleSuccess';

    constructor(public payload: Module) {}
}

export class CreateModuleFailure {
    static readonly type = '[Module] CreateModuleFailure';

    constructor(public payload: ICreateEntityFailureActionPayload) {}
}

export class UpdateModule {
    static readonly type = '[Module] UpdateModule';

    constructor(public payload: Module) {}
}

export class UpdateModuleSuccess {
    static readonly type = '[Module] UpdateModuleSuccess';

    constructor(public payload: Module | RelevantExecution) {}
}

export class UpdateModuleFailure {
    static readonly type = '[Module] UpdateModuleFailure';

    constructor(public payload: IUpdateEntityFailureActionPayload) {}
}

export class UpdateModuleSection {
    static readonly type = '[Module] UpdateModuleSection';

    constructor(public payload: Module) {}
}

export class UpdateModuleSectionSuccess {
    static readonly type = '[Module] UpdateModuleSectionSuccess';

    constructor() {}
}

export class UpdateModuleSectionFailure {
    static readonly type = '[Module] UpdateModuleSectionFailure';
}

export class DeleteModule {
    static readonly type = '[Module] DeleteModule';

    constructor(public payload: string) {}
}

export class DeleteModuleSuccess {
    static readonly type = '[Module] DeleteModuleSuccess';

    constructor(public payload: string) {}
}

export class DeleteModuleFailure {
    static readonly type = '[Module] DeleteModuleFailure';

    constructor(public payload: string) {}
}

export class SetModules {
    static readonly type = '[Module] SetModules';

    constructor(public payload: Module[]) {}
}

export class FetchModules {
    static readonly type = '[Module] FetchModules';
}

export class FetchModulesSuccess {
    static readonly type = '[Module] FetchModulesSuccess';
}

export class FetchModulesFailure {
    static readonly type = '[Module] FetchModulesFailure';
}

export class ToggleSelectedModule {
    static readonly type = '[Module] ToggleSelectedModule';

    constructor(public payload: string) {}
}

export class SelectFilteredModules {
    static readonly type = '[Module] SelectFilteredModules';
}

export class DeselectSelectedModules {
    static readonly type = '[Module] DeselectSelectedModules';
}

export class SearchModules {
    static readonly type = '[Module] SearchModules';

    constructor(public payload: string) {}
}

export class DeleteSelectedModules {
    static readonly type = '[Module] DeleteSelectedModules';
}

export class NotifyAllParticipants {
    static readonly type = '[Module] NotifyAllParticipants';

    constructor(public payload: INotifyAllParticipantsActionPayload) {}
}

export class NotifyAllParticipantsSuccess {
    static readonly type = '[Module] NotifyAllParticipantsSuccess';
}

export class NotifyAllParticipantsFailure {
    static readonly type = '[Module] NotifyAllParticipantsFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}

export class NotifySelectedParticipants {
    static readonly type = '[Module] NotifySelectedParticipants';

    constructor(public payload: INotifySelectedParticipantsActionPayload) {}
}

export class NotifySelectedParticipantsSuccess {
    static readonly type = '[Module] NotifySelectedParticipantsSuccess';
}

export class NotifySelectedParticipantsFailure {
    static readonly type = '[Module] NotifySelectedParticipantsFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}

export class ExportModule {
    static readonly type = '[Module] ExportModule';

    constructor(public payload: string) {}
}

export class ImportModule {
    static readonly type = '[Module] ImportModule';

    constructor(public file: File) {}
}

export class ImportModuleSuccess {
    static readonly type = '[Module] ImportModuleSuccess';

    constructor(public payload: Module) {}
}

export class ImportModuleFailure {
    static readonly type = '[Module] ImportModuleFailure';
}

export class FetchModuleFinalGradeMetrics {
    static readonly type = '[Module] FetchModuleFinalGradeMetrics';

    constructor(public payload: string) {}
}

export class FetchModuleFinalGradeMetricsSuccess {
    static readonly type = '[Module] FetchModuleFinalGradeMetricsSuccess';

    constructor(public payload: FinalGradeMetrics) {}
}

export class FetchModuleFinalGradeMetricsFailure {
    static readonly type = '[Module] FetchModuleFinalGradeMetricsFailure';

    constructor(public payload: IFetchEntityFailureActionPayload) {}
}

export class FetchTargetModules {
    static readonly type = '[Module] FetchTargetModules';

    constructor(public payload: Module) {}
}

export class FetchTargetModulesSuccess {
    static readonly type = '[Module] FetchTargetModulesSuccess';

    constructor(public payload: Module[]) {}
}

export class FetchTargetModulesFailure {
    static readonly type = '[Module] FetchTargetModulesFailure';
}

export class CopyModuleToExisting {
    static readonly type = '[Module] CopyModuleToExisting';

    constructor(
        public payload: {
            sourceModuleId: number;
            targetModuleId: number;
        }
    ) {}
}

export class CopyModuleToExistingSuccess {
    static readonly type = '[Module] CopyModuleToExistingSuccess';

    constructor(
        public payload: {
            id: string;
            asyncJobId: string;
        }
    ) {}
}

export class CopyModuleToExistingFailure {
    static readonly type = '[Module] CopyModuleToExistingFailure';
}

export class ConvertModuleToExecution {
    static readonly type = '[Module] ConvertModuleToExecution';

    constructor(
        public payload: {
            sourceModuleId: string;
            courseOfferingId: string;
        }
    ) {}
}

export class ConvertModuleToExecutionSuccess {
    static readonly type = '[Module] ConvertModuleToExecutionSuccess';

    constructor(public payload: Module) {}
}

export class ConvertModuleToExecutionFailure {
    static readonly type = '[Module] ConvertModuleToExecutionFailure';
}

export class ConvertExecutionToModule {
    static readonly type = '[Module] ConvertExecutionToModule';

    constructor(
        public payload: {
            sourceModuleId: string;
        }
    ) {}
}

export class ConvertExecutionToModuleSuccess {
    static readonly type = '[Module] ConvertExecutionToModuleSuccess';

    constructor(public payload: Module) {}
}

export class ConvertExecutionToModuleFailure {
    static readonly type = '[Module] ConvertExecutionToModuleFailure';
}

export class CopyModuleToNew {
    static readonly type = '[Module] CopyModuleToNew';

    constructor(public payload: ICopyModuleToNewActionPayload) {}
}

export class CopyModuleToNewSuccess {
    static readonly type = '[Module] CopyModuleToNewSuccess';

    constructor(
        public payload: {
            id: string;
            asyncJobId: string;
        }
    ) {}
}

export class CopyModuleToNewFailure {
    static readonly type = '[Module] CopyModuleToNewFailure';
}

export class CopyELearningModuleToNew {
    static readonly type = '[Module] CopyELearningModuleToNew';

    constructor(public payload: ICopyModuleToNewActionPayload) {}
}

export class CopyELearningModuleToNewSuccess {
    static readonly type = '[Module] CopyELearningModuleToNewSuccess';

    constructor(
        public payload: {
            id: string;
            asyncJobId: string;
        }
    ) {}
}

export class CopyELearningModuleToNewFailure {
    static readonly type = '[Module] CopyELearningModuleToNewFailure';
}

export class CreateELearningModule {
    static readonly type = '[Module] CreateELearningModule';

    constructor(public payload: ELearningModule) {}
}

export class CreateELearningModuleSuccess {
    static readonly type = '[Module] CreateELearningModuleSuccess';

    constructor(public payload: ELearningModule) {}
}

export class CreateELearningModuleFailure {
    static readonly type = '[Module] CreateELearningModuleFailure';

    constructor(public payload: ICreateEntityFailureActionPayload) {}
}

export class GetModuleLaunchCourseUrl {
    static readonly type = '[Module] GetModuleLaunchCourseUrl';

    constructor(public payload: string) {}
}

export class GetModuleLaunchCourseUrlSuccess {
    static readonly type = '[Module] GetModuleLaunchCourseUrlSuccess';

    constructor(public payload: any) {}
}

export class GetModuleLaunchCourseUrlFailure {
    static readonly type = '[Module] GetModuleLaunchCourseUrlFailure';
}

export class GetCourseStructureModuleBreadcrumbs {
    static readonly type = '[Module] GetCourseStructureModuleBreadcrumbs';

    constructor(public payload: string) {}
}

export class GetCourseStructureModuleBreadcrumbsSuccess {
    static readonly type = '[Module] GetCourseStructureModuleBreadcrumbsSuccess';

    constructor(public payload: CourseStructureModuleBreadcrumb[]) {}
}

export class GetCourseStructureModuleBreadcrumbsFailure {
    static readonly type = '[Module] GetCourseStructureModuleBreadcrumbsFailure';
}

export class GetModuleParticipantScormRegistrationMetrics {
    static readonly type = '[Module] GetModuleParticipantScormRegistrationMetrics';

    constructor(public payload: string) {}
}

export class GetModuleParticipantScormRegistrationMetricsSuccess {
    static readonly type = '[Module] GetModuleParticipantScormRegistrationMetricsSuccess';

    constructor(public payload: ParticipantRegistrationMetric) {}
}

export class GetModuleParticipantScormRegistrationMetricsFailure {
    static readonly type = '[Module] GetModuleParticipantScormRegistrationMetricsFailure';
}

export class UpdateELearningModule {
    static readonly type = '[Module] UpdateELearningModule';

    constructor(public payload: ELearningModule) {}
}

export class UpdateELearningModuleSuccess {
    static readonly type = '[Module] UpdateELearningModuleSuccess';

    constructor(public payload: ELearningModule) {}
}

export class UpdateELearningModuleFailure {
    static readonly type = '[Module] UpdateELearningModuleFailure';

    constructor(public payload: IUpdateEntityFailureActionPayload) {}
}

export class FetchModuleCourseStatus {
    static readonly type = '[Module] FetchModuleCourseStatus';

    constructor(
        public payload: {
            courseId: string;
        }
    ) {}
}

export class PatchModuleCourseId {
    static readonly type = '[Module] PatchModuleCourseId';

    constructor(
        public payload: {
            entityId: string;
            courseId: string;
        }
    ) {}
}

export class FetchModuleCourseStatusSuccess {
    static readonly type = '[Module] FetchModuleCourseStatusSuccess';
}

export class FetchModuleCourseStatusFailure {
    static readonly type = '[Module] FetchModuleCourseStatusFailure';
}

export class FetchModuleImportStatus {
    static readonly type = '[Module] FetchModuleImportStatus';

    constructor(
        public payload: {
            jobId: string;
            entityId: string;
        }
    ) {}
}

export class FetchModuleImportStatusSuccess {
    static readonly type = '[Module] FetchModuleImportStatusSuccess';

    constructor(public payload: ImportStatus) {}
}

export class FetchModuleImportStatusFailure {
    static readonly type = '[Module] FetchModuleImportStatusFailure';
}

export class UnsetModuleParticipantScormRegistrationMetrics {
    static readonly type = '[Module] UnsetModuleParticipantScormRegistrationMetrics';
}

export class UnsetModuleParticipantScormRegistrationMetricsSuccess {
    static readonly type = '[Module] UnsetModuleParticipantScormRegistrationMetricsSuccess';
}

export class UnsetModuleParticipantScormRegistrationMetricsFailure {
    static readonly type = '[Module] UnsetModuleParticipantScormRegistrationMetricsFailure';
}

export class ModuleSection {
    static readonly type = '[Module] ModuleSection';

    constructor(
        public payload: {
            id: string;
            isEnable: boolean;
        }
    ) {}
}

export class AddScheduleToModule {
    static readonly type = '[Module] AddScheduleToModule';

    constructor(
        public payload: {
            scheduledJob: ScheduledJob;
            entity: Module;
        }
    ) {}
}

export class UpdateScheduleInModule {
    static readonly type = '[Module] UpdateScheduleInModule';

    constructor(
        public payload: {
            scheduledJob: ScheduledJob;
            entity: Module;
        }
    ) {}
}

export class RemoveScheduleFromModule {
    static readonly type = '[Module] RemoveScheduleFromModule';

    constructor(
        public payload: {
            scheduledJobId: string;
            entity: Module;
        }
    ) {}
}

export class UpdateCompletionRule {
    static readonly type = '[Module] UpdateCompletionRule';

    constructor(public payload: { module: Module; requiredSectionForCompletion: string }) {}
}

export class UpdateCompletionRuleSuccess {
    static readonly type = '[Module] UpdateCompletionRuleSuccess';

    constructor(public payload: Module) {}
}

export class UpdateCompletionRuleFailure {
    static readonly type = '[Module] UpdateCompletionRuleFailure';

    constructor(public payload: IUpdateEntityFailureActionPayload) {}
}

export class FetchMinimumAttendance {
    static readonly type = '[Module] FetchMinimumAttendance';

    constructor(public payload: string) {}
}

export class FetchMinimumAttendanceSuccess {
    static readonly type = '[Module] FetchMinimumAttendanceSuccess';

    constructor(public payload: { minimumAttendance: number }) {}
}

export class FetchMinimumAttendanceFailure {
    static readonly type = '[Module] FetchMinimumAttendanceFailure';
}

export class UpdateMinimumAttendance {
    static readonly type = '[Module] UpdateMinimumAttendance';

    constructor(public payload: { id: string; minimumAttendance: number }) {}
}

export class UpdateMinimumAttendanceSuccess {
    static readonly type = '[Module] UpdateMinimumAttendanceSuccess';

    constructor(public payload: { minimumAttendance: number }) {}
}

export class UpdateMinimumAttendanceFailure {
    static readonly type = '[Module] UpdateMinimumAttendanceFailure';
}

export class SaveNotificationDefaultText {
    static readonly type = '[Module] SaveNotificationDefaultText';

    constructor(public payload: string) {}
}

export class SaveNotificationDefaultTextSuccess {
    static readonly type = '[Module] SaveNotificationDefaultTextSuccess';

    constructor(public payload: string) {}
}

export class SaveNotificationDefaultTextFailure {
    static readonly type = '[Module] SaveNotificationDefaultTextFailure';
}

export class FetchTenantAdminModuleStats {
    static readonly type = '[Module] FetchTenantAdminModuleStats';

    constructor(public payload: string) {}
}

export class FetchTenantAdminModuleStatsSuccess {
    static readonly type = '[Module] FetchTenantAdminModuleStatsSuccess';

    constructor(public payload: TenantAdminModuleStats) {}
}

export class FetchTenantAdminModuleStatsFailure {
    static readonly type = '[Module] FetchTenantAdminModuleStatsFailure';
}
