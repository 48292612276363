import { Injectable } from '@angular/core';
import { Actions, ofActionDispatched, Select, Store } from '@ngxs/store';
import { AudienceResource } from 'app/library/grow/models/audience-resource.model';
import { NgxsActionHelper } from 'app/projects/core/src/lib/services/action.helper';
import { BehaviorSubject, Observable, race } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
    CreateAudience,
    CreateAudienceSuccess,
    DeleteAudience,
    DeleteAudienceFailure,
    DeleteAudienceSuccess,
    FetchAudiences,
    FetchAudiencesFailure,
    FetchAudiencesSuccess,
    FetchAudienceWidgets,
    FetchAudienceWidgetsFailure,
    FetchAudienceWidgetsSuccess,
    FetchDefaultAudiences,
    FetchDefaultAudiencesFailure,
    FetchDefaultAudiencesSuccess,
    MoveToAudience,
    MoveToAudienceSuccess,
    UpdateAudience,
    UpdateAudienceFailure,
    UpdateAudienceSuccess,
} from '../actions/index';
import { AudienceWidget } from '../models/audience-widget.model';
import { MaxBrainAudience } from '../models/audience.model';
import { AudienceState } from '../models/audience.state';
import { AudiencesState } from '../models/audiences.state';

@Injectable()
export class AudienceService {
    @Select(AudienceState.getEntities)
    audiences$: Observable<MaxBrainAudience[]>;

    @Select(AudiencesState.getFilteredEntities)
    filteredAudience$: Observable<MaxBrainAudience[]>;

    @Select(AudiencesState.getDefaultAudiences)
    defaultAudiences$: Observable<MaxBrainAudience[]>;

    @Select(AudiencesState.getDefaultAudiencesTotalCount)
    defaultAudiencesTotalCount$: Observable<number>;

    private _newAudienceSubject = new BehaviorSubject<MaxBrainAudience>(null);
    public newAudienceSubject$ = this._newAudienceSubject.asObservable();

    constructor(private _store: Store, private _action$: Actions, private _actionHelper: NgxsActionHelper) {}

    getAudiences(): MaxBrainAudience[] {
        return this._store.selectSnapshot(AudiencesState.getFilteredEntities);
    }

    getAudienceById(id: string): MaxBrainAudience {
        return AudienceState.getEntity(this._store.selectSnapshot(AudienceState), id);
    }

    getDefaultAudiences(): MaxBrainAudience[] {
        return this._store.selectSnapshot(AudiencesState.getDefaultAudiences);
    }

    async fetchAudiences(search?: string): Promise<boolean> {
        this._store.dispatch(new FetchAudiences({ search }));

        return await this._actionHelper.race(FetchAudiencesSuccess, FetchAudiencesFailure).toPromise();
    }

    async createAudience(audience: MaxBrainAudience) {
        this._store.dispatch(new CreateAudience(audience));

        this._action$.pipe(ofActionDispatched(CreateAudienceSuccess), take(1)).subscribe((action: CreateAudienceSuccess) => {
            this._newAudienceSubject.next(action.payload);
        });
    }

    async updateAudience(audience: MaxBrainAudience): Promise<boolean> {
        this._store.dispatch(new UpdateAudience(audience));

        return await this._actionHelper.race(UpdateAudienceSuccess, UpdateAudienceFailure).toPromise();
    }

    moveToAudience(current: MaxBrainAudience, target: MaxBrainAudience) {
        this._store.dispatch(new MoveToAudience({ current, target }));

        this._action$.pipe(ofActionDispatched(MoveToAudienceSuccess), take(1)).subscribe((action: MoveToAudienceSuccess) => {
            this._newAudienceSubject.next(action.payload);
        });
    }

    deleteAudience(audienceId: string) {
        this._store.dispatch(new DeleteAudience(audienceId));

        return race(
            this._action$.pipe(
                ofActionDispatched(DeleteAudienceSuccess),
                map((action: DeleteAudienceSuccess) => action.payload)
            ),
            this._action$.pipe(
                ofActionDispatched(DeleteAudienceFailure),
                map(() => null)
            )
        )
            .pipe(take(1))
            .toPromise();
    }

    async fetchAudienceWidgets(): Promise<AudienceWidget[]> {
        this._store.dispatch(new FetchAudienceWidgets());

        return race(
            this._action$.pipe(
                ofActionDispatched(FetchAudienceWidgetsSuccess),
                map((action: FetchAudienceWidgetsSuccess) => action.payload)
            ),
            this._action$.pipe(
                ofActionDispatched(FetchAudienceWidgetsFailure),
                map((action: FetchAudienceWidgetsFailure) => null)
            )
        )
            .pipe(take(1))
            .toPromise();
    }

    searchTree(audienceTree, matchingId: string) {
        if (audienceTree.id == matchingId) {
            return audienceTree;
        } else if (audienceTree.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < audienceTree.children.length; i++) {
                result = this.searchTree(audienceTree.children[i], matchingId);
            }
            return result;
        }
        return null;
    }

    searchTreeName(audienceTree, matchingId: string, names: string = ''): string {
        if (audienceTree.id == matchingId) {
            names = names + '/' + audienceTree.name;
            names = names.substring(1); // removes first '/' charachter
            return names.substring(names.indexOf('/') + 1); // removes default audience from a path
        } else if (audienceTree.children != null) {
            var i;
            var result = null;
            names = names + '/' + audienceTree.name;
            for (i = 0; result == null && i < audienceTree.children.length; i++) {
                result = this.searchTreeName(audienceTree.children[i], matchingId, names);
            }
            return result;
        }
        return null;
    }

    convertListToTree(list: any[]): AudienceResource[] {
        const map: object = {};
        const roots: any[] = [];

        let node, i;

        for (i = 0; i < list.length; i += 1) {
            map[list[i].id] = i; // initialize the map
            list[i].children = []; // initialize the children
        }

        for (i = 0; i < list.length; i += 1) {
            node = list[i];
            if (node.parent && node.parent.id !== '0') {
                if (list[map[node.parent.id]]) {
                    list[map[node.parent.id]].children.push(node);
                }
            } else {
                roots.push(node);
            }
        }
        return roots;
    }

    async fetchDefaultAudiences(pageSize?: number, pageIndex?: number): Promise<boolean> {
        this._store.dispatch(new FetchDefaultAudiences({ pageSize, pageIndex }));

        return await this._actionHelper.race(FetchDefaultAudiencesSuccess, FetchDefaultAudiencesFailure).toPromise();
    }
}
