import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { FetchAudiences } from 'app/library/audience/actions';
import { MaxBrainAudience } from 'app/library/audience/models/audience.model';
import { UnsetModuleParticipants } from 'app/library/module-participants/actions';
import { MaxBrainUtils } from 'app/projects/core/src/lib/utils';
import { EntityState } from 'app/projects/entity/src/lib/models/entity.state';
import { AddUsers } from 'app/projects/user/src/lib/actions/add-entities.action';
import { User } from 'app/projects/user/src/lib/models/user';
import { UserState } from 'app/projects/user/src/lib/models/user.state';
import {
    AddUsersToAudience,
    AddUsersToAudienceFailure,
    AddUsersToAudienceSuccess,
    AssignUserToAudience,
    AssignUserToAudienceFailure,
    AssignUserToAudienceSuccess,
    DeleteAudienceUsers,
    DeleteAudienceUsersFailure,
    DeleteAudienceUsersSuccess,
    FetchAudienceByUserId,
    FetchAudienceByUserIdFailure,
    FetchAudienceByUserIdSuccess,
    FetchAudienceUser,
    FetchAudienceUserFailure,
    FetchAudienceUserSuccess,
    MoveAudienceUsers,
    MoveAudienceUsersFailure,
    MoveAudienceUsersSuccess,
    SetAudienceUser,
    UnsetAudienceUser,
    UpdateAudienceUser,
    UpdateAudienceUserFailure,
    UpdateAudienceUserSuccess,
} from '../actions';
import { AddAudienceUsers } from '../actions/add-entities.action';
import { AudienceUserApiService } from '../services/audience-user.api-service';
import { AudienceUser } from './audience-user.model';
import { AudienceUserStateModel } from './audience-user.state-model';
import { AudienceUserExtended } from './audience-user.extended.model';

// Note: THIS STATE DOES NOT HAVE TO HAVE A FETCH
// In this state we track userId, audienceId, and id (check AudienceUser)
@State<AudienceUserStateModel>({
    name: 'audienceUser',
    defaults: new AudienceUserStateModel(),
})
@Injectable()
export class AudienceUserState extends EntityState<AudienceUser> {
    static sortProperties: string[] = ['id'];

    @Selector()
    static getEntity(state: AudienceUserStateModel, id: string = state.item): AudienceUser {
        if (!state.map) {
            return null;
        }

        return state.map.get(id) || null;
    }

    @Selector()
    static getEntitiesAsMap(state: AudienceUserStateModel): Map<string, AudienceUser> {
        if (!state.map) {
            return null;
        }
        return state.map;
    }

    @Selector()
    static getEntities(state: AudienceUserStateModel): AudienceUser[] {
        if (!state.map) {
            return null;
        }

        const toReturn = Array.from(state.map.values());

        toReturn.sort(MaxBrainUtils.sortCompareFn(AudienceUserState.sortProperties));

        return toReturn;
    }

    @Selector()
    static getPageNumber(state: AudienceUserStateModel): number {
        return state.pageNumber || 1;
    }

    @Selector()
    static getTotalCount(state: AudienceUserStateModel): number {
        return state.totalCount;
    }

    constructor(private audienceUserApiService: AudienceUserApiService, private _store: Store) {
        super(null, 0);
    }

    @Action(SetAudienceUser)
    setEntity(ctx: StateContext<AudienceUserStateModel>, action: SetAudienceUser): void {
        this._setEntity(ctx, action, AddAudienceUsers);
    }

    @Action(UnsetAudienceUser)
    unsetEntity(ctx: StateContext<AudienceUserStateModel>): void {
        this._unsetEntity(ctx);

        ctx.dispatch(new UnsetModuleParticipants());
    }

    @Action(AddAudienceUsers)
    addEntities(ctx: StateContext<AudienceUserStateModel>, action: AddAudienceUsers): void {
        const users: User[] = [];
        const audiences: MaxBrainAudience[] = [];

        action.payload = action.payload.map((moduleUserExtended) => {
            if (moduleUserExtended.user) {
                if (users.map((user) => user.id).indexOf(moduleUserExtended.user.id) === -1) {
                    if (moduleUserExtended.user.alreadyMember !== true && moduleUserExtended.user.alreadyMember !== false) {
                        // this IF is needed because the moduleUserExtended.user that comes from the API is missing these properties
                        const user = UserState.getEntity(this._store.selectSnapshot(UserState), moduleUserExtended.user.id);

                        users.push(user ? Object.assign(moduleUserExtended.user, { analytics: user.analytics, alreadyMember: user.alreadyMember }) : moduleUserExtended.user);
                    } else {
                        users.push(moduleUserExtended.user);
                    }
                }

                delete moduleUserExtended.user;
            }

            if (moduleUserExtended.audience) {
                if (audiences.map((module) => module.id).indexOf(moduleUserExtended.audience.id) === -1) {
                    audiences.push(moduleUserExtended.audience);
                }

                delete moduleUserExtended.audience;
            }

            return moduleUserExtended;
        });

        ctx.dispatch(new AddUsers(users));
        //ctx.dispatch(new AddAudiences(audiences)); //TODO: ask Nikola is adding audiences rly needed? MAX-6601

        this._addEntities(ctx, action);
    }

    @Action(UpdateAudienceUser)
    updateEntity(ctx: StateContext<AudienceUserStateModel>, action: UpdateAudienceUser): void {
        this._updateEntity(ctx, action, UpdateAudienceUserSuccess, UpdateAudienceUserFailure, AddAudienceUsers, this.audienceUserApiService.update(action.payload));
    }

    @Action(UpdateAudienceUserSuccess)
    updateAudienceUserSuccess({ dispatch }: StateContext<AudienceUserStateModel>, { payload }: UpdateAudienceUserSuccess): void {
        dispatch([new FetchAudiences(), new UpdateAudienceUser(payload)]);
    }

    @Action(FetchAudienceUser)
    fetchEntity(ctx: StateContext<AudienceUserStateModel>, action: FetchAudienceUser): void {
        this._fetchEntity(ctx, action, FetchAudienceUserSuccess, FetchAudienceUserFailure, AddAudienceUsers, SetAudienceUser, this.audienceUserApiService.read(action.payload));
    }

    @Action(FetchAudienceUserSuccess)
    fetchEntitySuccess(ctx: StateContext<AudienceUserStateModel>, action: FetchAudienceUserSuccess): void {
        this._fetchEntitySuccess(ctx, action);
    }

    @Action(FetchAudienceUserFailure)
    fetchEntityFailure(ctx: StateContext<AudienceUserStateModel>, action: FetchAudienceUserFailure): void {
        this._fetchEntityFailure(ctx, action);
    }

    @Action(AddUsersToAudience)
    addUsersToAudience({ dispatch }: StateContext<AudienceUserStateModel>, { payload }: AddUsersToAudience): void {
        this.audienceUserApiService.addUsersToAudience(payload).subscribe(
            (entities) => {
                dispatch([new AddAudienceUsers(entities.newlyAssigned), new AddUsersToAudienceSuccess(entities)]);
            },
            (response: HttpErrorResponse) => {
                dispatch(new AddUsersToAudienceFailure({ response }));
            }
        );
    }

    @Action(AssignUserToAudience)
    assignUserToAudience({ dispatch, getState }: StateContext<AudienceUserStateModel>, { payload }: AssignUserToAudience): void {
        this.audienceUserApiService.assignUserToAudience(payload).subscribe(
            (entities) => {
                dispatch(
                    new AssignUserToAudienceSuccess({
                        newlyAssigned: entities.newlyAssigned,
                        alreadyInChildren: entities.alreadyInChildren,
                        alreadyAssigned: entities.alreadyAssigned,
                    })
                );
            },
            (response: HttpErrorResponse) => {
                dispatch(new AssignUserToAudienceFailure({ response }));
            }
        );
    }

    @Action(MoveAudienceUsers)
    moveAudienceUsers({ dispatch, getState }: StateContext<AudienceUserStateModel>, { payload }: MoveAudienceUsers): void {
        this.audienceUserApiService.moveUsersFromAudience(payload).subscribe(
            () => {
                dispatch(new MoveAudienceUsersSuccess(''));
            },
            (response: HttpErrorResponse) => {
                dispatch(new MoveAudienceUsersFailure({ response }));
            }
        );
    }

    @Action(DeleteAudienceUsers)
    removeUsersFromAudiences({ dispatch, getState }: StateContext<AudienceUserStateModel>, { payload }: DeleteAudienceUsers): void {
        this.audienceUserApiService.removeUsersFromAudiences(payload).subscribe(
            () => {
                dispatch(new DeleteAudienceUsersSuccess(''));
            },
            (response: HttpErrorResponse) => {
                dispatch(new DeleteAudienceUsersFailure({ response }));
            }
        );
    }

    @Action(FetchAudienceByUserId)
    fetchEntityById(ctx: StateContext<AudienceUserStateModel>, { payload }: FetchAudienceByUserId): void {
        this.audienceUserApiService.getAudiencesByUserId(payload.userId, payload.pageSize, payload.pageIndex).subscribe(
            ({ data, pagination }) => {
                ctx.patchState({ totalCount: pagination.number_of_results, pageNumber: pagination.number_of_pages });

                const userAudiences = data.map((query) => new AudienceUserExtended(query));

                ctx.dispatch(new FetchAudienceByUserIdSuccess(userAudiences));
            },
            (response: HttpErrorResponse) => {
                ctx.dispatch(new FetchAudienceByUserIdFailure({ response }));
            }
        );
    }
}
