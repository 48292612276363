import { AudienceUser } from './audience-user.model';

export class AudienceUserCommand {
    user: { id: string };
    module: { id: string };

    constructor(audienceUser: AudienceUser) {
        this.user = {
            id: audienceUser.userId,
        };
        this.module = {
            id: audienceUser.audienceId,
        };
    }
}
