import * as momentImported from 'moment';
import { IAudienceQuery } from '../interfaces/audience.query.interface';
const moment = momentImported;

// @dynamic
export class MaxBrainAudience {
    static label_singular = 'audience';
    static label_plural = 'audiences';

    static props_to_search = ['name'];

    id: string;
    name: string;
    lastUpdate: momentImported.Moment;
    memberCount: number;
    emptyWidgets: number[];
    isAutoGenerated: boolean;
    type: number;
    hasEmptyWidget: boolean;
    isSelected?: boolean;
    parent: {
        id: string;
        name: string;
        lastUpdate: momentImported.Moment;
        memberCount: number;
        emptyWidgets: number[];
        isAutoGenerated: boolean;
        type: number;
        hasEmptyWidget: boolean;
        open: boolean; // Frontend property
    };
    open: boolean; // Frontend property
    isDefault: boolean;

    constructor(query?: IAudienceQuery) {
        if (query) {
            this.id = query.id.toString();
            this.name = query.name;
            this.lastUpdate = query.last_update ? moment(query.last_update) : null;
            this.memberCount = query.member_count;
            this.emptyWidgets = query.empty_widgets;
            this.isAutoGenerated = query.is_auto_generated;
            this.type = query.type;
            this.hasEmptyWidget = query.has_empty_widget;
            this.open = false;

            const { parent, empty_widgets, is_auto_generated, type, has_empty_widget } = query;

            this.parent = parent
                ? {
                      id: parent.id.toString(),
                      name: parent.name,
                      lastUpdate: parent.last_update ? moment(parent.last_update) : null,
                      memberCount: parent.member_count,
                      emptyWidgets: empty_widgets,
                      isAutoGenerated: is_auto_generated,
                      type,
                      hasEmptyWidget: has_empty_widget,
                      open: false,
                  }
                : null;
            this.isDefault = query.is_default;
        }
    }
}
