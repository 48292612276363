import { Certification } from 'app/library/certifications/models/certification';
import * as momentImported from 'moment';
import { IOfficialCertificateMetadataQuery } from '../interfaces/official-certificate-metadata.query.interface';
const moment = momentImported;

export class OfficialCertificateMetadata {
    expirationDate: momentImported.Moment;
    hasFile: boolean;
    issueDate: momentImported.Moment;
    replacementCertificate: number;
    status: number;
    certification?: Certification;
    notifyAboutExpiration: boolean;

    constructor(query: IOfficialCertificateMetadataQuery) {
        if (query) {
            this.expirationDate = moment(query.expiration_date, 'YYYY-MM-DD');
            this.hasFile = query.has_file;
            this.issueDate = moment(query.issue_date, 'YYYY-MM-DD');
            this.replacementCertificate = query.replacement_certificate;
            this.status = query.status;
            this.notifyAboutExpiration = query.notify_about_expiration;
            if (query.certification) {
                this.certification = new Certification(query.certification);
            }
        } else {
            this.expirationDate = null;
            this.hasFile = false;
            this.issueDate = null;
            this.replacementCertificate = 0;
            this.status = 0;
            this.certification = null;
            this.notifyAboutExpiration = false;
        }
    }
}
