import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from 'app/projects/core/src/lib/interfaces/environment.interface';
import { MAXBRAIN_ENVIRONMENT } from 'app/projects/core/src/lib/services/environment.token';
import { IEntityApiService } from 'app/projects/entity/src/lib/interfaces/entity.service.interface';
import { EntityService } from 'app/projects/entity/src/lib/services/entity.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAudienceWidgetQuery } from '../interfaces/audience-widget.query';
import { IAudienceQuery } from '../interfaces/audience.query.interface';
import { AudienceMoveToCommand } from '../models/audience-moveTo.command.model';
import { AudienceUpdateCommand } from '../models/audience-update.command.model';
import { AudienceWidget } from '../models/audience-widget.model';
import { AudienceCommand } from '../models/audience.command.model';
import { MaxBrainAudience } from '../models/audience.model';

export interface IDefaultAudiencesAndTotalCount {
    data: IAudienceQuery[];
    pagination: {
        number_of_pages: number;
        number_of_results: number;
    };
}

@Injectable()
export class AudienceApiService extends EntityService<IAudienceQuery, AudienceCommand> implements IEntityApiService<MaxBrainAudience> {
    constructor(http: HttpClient, @Inject(MAXBRAIN_ENVIRONMENT) environment: IEnvironment) {
        super(http, environment.apiUrl, 'engage/audiences');
    }

    getAll(search?: string): Observable<MaxBrainAudience[]> {
        let params = new HttpParams();
        if (search) {
            params = params.set('search', search);
        }
        return this.http.get<IAudienceQuery[]>(`${this.apiUrl}/${this.entities}`, { params }).pipe(map((queries) => queries.map((query) => new MaxBrainAudience(query))));
    }

    create(entity: MaxBrainAudience): Observable<MaxBrainAudience> {
        const data = new AudienceCommand(entity);

        return this._create(data).pipe(map((query) => new MaxBrainAudience(query)));
    }

    read(id: string): Observable<MaxBrainAudience> {
        return this._read(id).pipe(map((query) => new MaxBrainAudience(query)));
    }

    fetchAudienceWidgets(): Observable<AudienceWidget[]> {
        return this.http.get<IAudienceWidgetQuery[]>(`${this.apiUrl}/${this.entities}/widgets`).pipe(map((queries) => queries.map((query) => new AudienceWidget(query))));
    }

    update(entity: MaxBrainAudience): Observable<MaxBrainAudience> {
        const data = new AudienceUpdateCommand(entity);
        return this.http.patch<IAudienceQuery>(`${this.apiUrl}/${this.entities}/${entity.id}`, data).pipe(map((query) => new MaxBrainAudience(query)));
    }

    delete(id: string): Observable<any> {
        return this._delete(id);
    }

    moveTo(current: MaxBrainAudience, target: MaxBrainAudience): Observable<MaxBrainAudience> {
        const data = new AudienceMoveToCommand(target);
        return this.http.patch<IAudienceQuery>(`${this.apiUrl}/${this.entities}/${current.id}`, data).pipe(map((query) => new MaxBrainAudience(query)));
    }

    getDefaultAudiences(pageSize = 10, pageIndex = 1): Observable<IDefaultAudiencesAndTotalCount> {
        let params = new HttpParams().set('page', pageIndex.toString()).set('limit', pageSize.toString());

        return this.http.get<IDefaultAudiencesAndTotalCount>(`${this.apiUrl}/engage/audiences/default`, { params });
    }
}
