import * as momentImported from 'moment';
import { SurveyInfo } from './survey-info.model';
const moment = momentImported;

export class SetSurveySectionCommandModel {
    survey: {
        description: string;
        show_description_after_completion: boolean;
        survey_info: {
            end_date: string;
            completion_text: string;
            due_date: string;
        };
        template_id: number;
    };

    constructor(surveyInfo: SurveyInfo, descriptionVisibility: boolean, description?: string, templateId?: string) {
        this.survey = {
            description,
            show_description_after_completion: descriptionVisibility,
            survey_info: {
                end_date: surveyInfo.endDate ? moment(surveyInfo.endDate).toISOString(true) : null,
                completion_text: surveyInfo.completionText,
                due_date: surveyInfo.dueDate ? moment(surveyInfo.dueDate).toISOString(true) : null,
            },
            template_id: parseInt(templateId),
        };
    }
}
