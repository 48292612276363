import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { CompletionRule } from 'app/library/course-offering/enums/completion-rule.enum';
import { AutomaticallyBySetRules } from 'app/library/module/models/automatically-by-set-rules';

@Pipe({
    name: 'getStatusByValue',
})
@Injectable()
export class GetStatusByValuePipe implements PipeTransform {
    transform(statusValue: number, entityStatuses: any, property: string): number {
        if (entityStatuses === AutomaticallyBySetRules && (statusValue === 8 || statusValue === 9 || statusValue === 10)) {
            statusValue = CompletionRule.SECTIONS;
        }

        if (statusValue === undefined || statusValue === null) {
            return null;
        }

        const entityStatus = entityStatuses.all.find((status) => status.value === statusValue);

        if (!entityStatus) {
            return statusValue;
        }

        return entityStatus[property];
    }
}
