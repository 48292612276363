import { MaxBrainAudience } from './audience.model';

export class AudienceCommand {
    name: string;
    parent: { id: string };

    constructor(tempAudience: MaxBrainAudience) {
        this.name = tempAudience.name;
        this.parent = { id: tempAudience.parent.id };
    }
}
