<div *ngIf="!creating">
    <app-dialog-header [title]="'USER.BUTTON.ADD_NEW'" (onClick)="dialogRef.close(null)"></app-dialog-header>

    <div mat-dialog-content fxLayout="column" fxLayoutAlign="start" class="p-24">
        <mat-horizontal-stepper [linear]="true" labelPosition="bottom" #stepper>
            <mat-step>
                <ng-template matStepLabel>{{ 'GROUPS.LABEL.GENERAL_INFO' | translate }}</ng-template>

                <form [formGroup]="detailsFormGroup" class="w-100-p">
                    <div class="mb-24 relative user-header" fxLayout="row" fxLayoutAlign="start center">
                        <input
                            type="file"
                            style="display: none"
                            (change)="fileChange($event)"
                            [placeholder]="'CONTENT.BUTTON.UPLOAD' | translate"
                            accept=".png, .jpg, .jpeg, .bmp, .gif"
                            #fileInput
                        />
                        <img [src]="profilePictureSrc" class="avatar user mr-24" />

                        <app-white-button [label]="'PAGE.USER_FORM.BUTTON.CHOOSE_PHOTO'" (onClick)="fileInput.click()"></app-white-button>
                    </div>

                    <mat-form-field class="w-100-p" formGroupName="role">
                        <mat-select formControlName="userRole" [placeholder]="'PAGE.USER_FORM.TAB.ACCOUNT.FIELD.ROLE' | translate">
                            <mat-option value="basic_role">
                                {{ 'USER.SINGULAR' | translate }}
                            </mat-option>
                            <mat-option value="tenant_management">
                                {{ 'GENERAL.ADMINISTRATOR' | translate }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="userRoleForm.controls['userRole'].errors && userRoleForm.controls['userRole'].errors['message']">
                            {{ 'ERROR.RESPONSE.' + userRoleForm.controls['userRole'].errors['message'] | translate }}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-100-p" formGroupName="account">
                        <input matInput name="first-name" formControlName="firstName" [placeholder]="'GENERAL.PROPERTY.FIRST_NAME' | translate" />
                        <mat-error *ngIf="userAccountForm.controls['firstName'].errors && userAccountForm.controls['firstName'].errors['message']">
                            {{ 'ERROR.RESPONSE.' + userAccountForm.controls['firstName'].errors['message'] | translate }}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-100-p" formGroupName="account">
                        <input matInput name="last-name" formControlName="lastName" [placeholder]="'GENERAL.PROPERTY.LAST_NAME' | translate" />
                        <mat-error *ngIf="userAccountForm.controls['lastName'].errors && userAccountForm.controls['lastName'].errors['message']">
                            {{ 'ERROR.RESPONSE.' + userAccountForm.controls['lastName'].errors['message'] | translate }}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-100-p" formGroupName="account">
                        <input matInput name="email" formControlName="email" [placeholder]="'GENERAL.PROPERTY.EMAIL' | translate" />
                        <mat-error *ngIf="userAccountForm.controls['email'].errors && userAccountForm.controls['email'].errors['message']">
                            {{ 'ERROR.RESPONSE.' + userAccountForm.controls['email'].errors['message'] | translate }}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-100-p" formGroupName="account">
                        <mat-select name="language" formControlName="language" [placeholder]="'GENERAL.PROPERTY.LANGUAGE' | translate">
                            <mat-option *ngFor="let lang of languageService.languages | filterLanguages" [value]="lang.id">
                                <span fxLayout="row" fxLayoutAlign="start center">
                                    <img class="flag mr-16" [src]="'assets/images/flags/' + lang.flag + '.svg'" />
                                    <span class="iso" [translate]="lang.translate">{{ lang.title }}</span>
                                </span>
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="userAccountForm.controls['language'].errors && userAccountForm.controls['language'].errors['message']">
                            {{ 'ERROR.RESPONSE.' + userAccountForm.controls['language'].errors['message'] | translate }}
                        </mat-error>
                    </mat-form-field>
                </form>
            </mat-step>
            <mat-step *ngIf="isEngageFeatureEnabled">
                <ng-template matStepLabel>{{ 'GENERAL.LABEL.EXPERIENCE_AUDIENCE' | translate }}</ng-template>

                <div class="bg-lightgreybackground mb-8">
                    <mat-toolbar-row class="table-toolbar" fxLayout="row" fxLayoutAlign="space-between center">
                        <span class="toolbar-title">{{ 'GENERAL.BUTTON.ASSIGN_TO_EXPERIENCE' | translate }}</span>
                    </mat-toolbar-row>
                    <div>
                        <form [formGroup]="detailsFormGroup" class="w-100-p">
                            <mat-form-field class="w-100-p px-24" formGroupName="account">
                                <mat-label>{{ 'ENGAGE.EXPERIENCE.TITLE.SINGULAR' | translate }}</mat-label>
                                <mat-select formControlName="experienceId">
                                    <mat-optgroup *ngFor="let experience of experiences; let j = index">
                                        <mat-option [class.selected]="experience.open" [value]="experience.id">
                                            {{ experience.name }}
                                        </mat-option>
                                    </mat-optgroup>
                                </mat-select>
                            </mat-form-field>
                        </form>
                    </div>
                </div>

                <div class="bg-lightgreybackground">
                    <mat-toolbar-row class="table-toolbar" fxLayout="row" fxLayoutAlign="space-between center">
                        <span class="toolbar-title">{{ 'GENERAL.BUTTON.ASSIGN_TO_AUDIENCE' | translate }}</span>
                        <app-white-button
                            [label]="'ENGAGE.BUTTONS.ADD_AUDIENCE'"
                            [icon]="'add'"
                            (onClick)="openNewAudienceDialog()"
                            *ngIf="(audienceService.defaultAudiences$ | async)?.length"
                        ></app-white-button>
                    </mat-toolbar-row>

                    <div>
                        <div fxLayoutAlign="center center" class="p-48" *ngIf="!(audienceService.defaultAudiences$ | async)?.length; else audiencesTable">
                            <mat-icon class="participant-view-illustration mr-16" svgIcon="default_audience"> </mat-icon>
                            <div>
                                <h2 class="mb-16">{{ 'ENGAGE.LABELS.NO_DEFAULT_AUDIENCE' | translate }}</h2>
                                <app-flat-button [label]="'ENGAGE.BUTTONS.ADD_AUDIENCE'" [icon]="'add'" (onClick)="openNewAudienceDialog()"></app-flat-button>
                            </div>
                        </div>
                        <ng-template #audiencesTable>
                            <div class="w-100-p p-24 table-wrapper" *ngIf="dataSource">
                                <mat-table #table [dataSource]="dataSource">
                                    <!-- Audience Column -->
                                    <ng-container matColumnDef="audienceName">
                                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                                        <mat-cell *matCellDef="let audience" fxLayout="column" fxLayoutAlign="center flex-start">
                                            <p class="text-truncate text-darktextcolor font-medium m-0">{{ audience.name }}</p>
                                            <p class="text-truncate text-darktextcolor m-0">{{ audience.parent?.name }}</p>
                                        </mat-cell>
                                    </ng-container>

                                    <!-- Buttons Column -->
                                    <ng-container matColumnDef="buttons">
                                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                                        <mat-cell *matCellDef="let audience">
                                            <div class="w-100-p">
                                                <app-white-button
                                                    [label]="'GENERAL.BUTTON.REMOVE'"
                                                    [icon]="'delete'"
                                                    (onClick)="removeDefaultAudience(audience)"
                                                ></app-white-button>
                                            </div>
                                        </mat-cell>
                                    </ng-container>

                                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                    <mat-row *matRowDef="let audience; columns: displayedColumns" matRipple> </mat-row>
                                </mat-table>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </mat-step>
        </mat-horizontal-stepper>
    </div>
    <div mat-dialog-actions class="pt-0 action-buttons" fxLayout="row" fxLayoutAlign="space-between center">
        <app-white-button class="mr-8" [label]="'GENERAL.BUTTON.CANCEL'" (onClick)="dialogRef.close(null)"></app-white-button>

        <div fxLayout="row" fxLayoutAlign="flex-end center">
            <app-flat-button [label]="'GENERAL.BUTTON.NEXT'" class="mr-8" [disabled]="isLastStep" (onClick)="goForward(stepper)" *ngIf="shouldShowNextButton"></app-flat-button>
            <app-flat-button [label]="'GENERAL.BUTTON.BACK'" class="mr-8" (onClick)="goBack(stepper)" *ngIf="isLastStep"></app-flat-button>

            <app-flat-button
                [label]="'GENERAL.BUTTON.CREATE'"
                (onClick)="create()"
                [disabled]="detailsFormGroup.invalid || detailsFormGroup.pristine"
                *ngIf="isLastStep"
            ></app-flat-button>
        </div>
    </div>
</div>
<div class="p-24" fxLayout="column" fxLayoutAlign="center center" *ngIf="creating">
    <div class="p-24">
        <mat-spinner></mat-spinner>
    </div>
    <p class="center">{{ 'USER.MESSAGE.CREATING' | translate }}.<br />{{ 'GENERAL.MESSAGE.PLEASE_WAIT' | translate }}.</p>
</div>
