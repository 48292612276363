import { IAudienceUserQuery } from '../interfaces/audience-user.query.interface';

export class AudienceUser {
    static label_singular = 'audienceUser';
    static label_plural = 'audienceUsers';

    static props_to_search = [];

    id: string;
    audienceId: string;
    userId: string;

    constructor(audienceUser?: IAudienceUserQuery) {
        if (audienceUser) {
            this.audienceId = audienceUser.audience ? audienceUser.audience.id.toString() : '';
            this.userId = audienceUser.user.id.toString();
            this.id = audienceUser.audience ? audienceUser.id.toString() : audienceUser.user.id.toString();
        } else {
            this.audienceId = null;
            this.userId = null;
            this.id = null;
        }
    }
}
