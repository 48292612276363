import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { FetchDefaultAudiencesSuccess } from 'app/library/audience/actions';
import { MaxBrainAudience } from 'app/library/audience/models/audience.model';
import { AudienceService } from 'app/library/audience/services/audience.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

export class DefaultAudiencesDataSource implements DataSource<MaxBrainAudience> {
    private _loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this._loadingSubject.asObservable();

    constructor(private _filteredDefaultAudiences$: Observable<MaxBrainAudience[]>, private _store: Store, private _action$: Actions, private _audienceService: AudienceService) {}

    connect(collectionViewer: CollectionViewer): Observable<MaxBrainAudience[]> {
        return this._filteredDefaultAudiences$;
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this._loadingSubject.complete();
    }

    async loadDefaultAudiences(pageSize?: number, pageNumber?: number): Promise<void> {
        this._loadingSubject.next(true);

        await this._audienceService.fetchDefaultAudiences(pageSize, pageNumber);

        this._action$
            .pipe(
                ofActionDispatched(FetchDefaultAudiencesSuccess),
                finalize(() => this._loadingSubject.next(false)),
                take(1)
            )
            .subscribe();
    }
}
