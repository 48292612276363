<div [hidden]="hide" #card>
    <mat-card class="user-details-card" [class.visible]="isCardVisible" [ngStyle]="{ 'top.px': cardTopValue, 'left.px': cardLeftValue }">
        <div class="content" #content>
            <div class="card-header" fxLayout="row" fxLayoutAlign="space-between">
                <div class="pr-12" fxLayout="row" fxLayoutAlign="start center">
                    <div class="relative">
                        <img class="avatar" *ngIf="user?.avatar.src; else initials" [alt]="user?.firstName" [src]="user?.avatar.src" />
                        <ng-template #initials>
                            <div fxLayoutAlign="center center" class="avatar-initials avatar">{{ user?.fullName | initials }}</div>
                        </ng-template>
                        <ng-template [onlineStateIndicator]="user?.isOnline"> </ng-template>
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center start">
                        <div *ngIf="isTitleClickable; else titleBasic">
                            <h3 class="title pointer" (click)="goTo()">
                                <a>{{ user?.firstName }} {{ user?.lastName }}</a>
                            </h3>
                            <a [href]="'mailto:' + user?.email" target="_top" *ngIf="showEmail && user?.email"> {{ user?.email }}</a>
                        </div>
                        <ng-template #titleBasic>
                            <h3 class="title">
                                <p>{{ user?.firstName }} {{ user?.lastName }}</p>
                            </h3>
                            <a [href]="'mailto:' + user?.email" target="_top" *ngIf="showEmail && user?.email"> {{ user?.email }}</a>
                        </ng-template>
                        <div class="social-wrapper" *ngIf="user?.socialNetworks?.length">
                            <a *ngFor="let network of user?.socialNetworks" href="{{ network.value }}" target="_blank">
                                <img class="social-icon" [alt]="network.type" [src]="network.imageUrl" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-content">
                <ng-container *ngFor="let item of user.attributes">
                    <div class="user-info">
                        <span class="key">{{ item.attribute.translationKey | translate }}:</span>
                        <span class="value" [matTooltip]="(item.attribute | getUserExtendedAttributeValue: user.attributes) || ''" matTooltipPosition="above">
                            {{ (item.attribute | getUserExtendedAttributeValue: user.attributes) || '-' }}</span
                        >
                    </div>
                </ng-container>
                <app-flat-button
                    class="mt-16 w-100-p"
                    [svgIcon]="'chat_bubble'"
                    [label]="'GENERAL.PROPERTY.SEND_MESSAGE'"
                    (onClick)="startConversation()"
                    *ngIf="!fuseConfig.layout.sidepanel.hidden && user?.id !== profileId"
                ></app-flat-button>
            </div>
        </div>
    </mat-card>
</div>
