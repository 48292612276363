import { CertificateType } from 'app/library/certificate/enums/certificate-type.enum';
import { FinalGrade } from 'app/library/final-grade/models/final-grade.model';
import { ModuleUserExtended } from 'app/library/module-user/models/module-user.extended.model';
import { Module } from 'app/library/module/models/module.model';
import { User } from 'app/projects/user/src/lib/models/user';
import * as moment from 'moment';
import { ICertificateRecordQuery } from '../interfaces/certificate-record.query.interface';
import { OfficialCertificateMetadata } from './official-certificate-metadata';

export class CertificateRecord {
    id: string;
    name: string;
    moduleUser: ModuleUserExtended;
    module: Module;
    owner: User;
    isViewedByOwner: boolean;
    type: CertificateType;
    officialCertificateMetadata?: OfficialCertificateMetadata;
    createdAt?: moment.Moment;
    finalGrade?: FinalGrade;
    filename?: string;

    constructor(query: ICertificateRecordQuery) {
        this.id = query.id?.toString();
        this.name = query.name;
        this.moduleUser = new ModuleUserExtended(query.module_user);
        this.module = new Module(query.module);
        this.owner = new User(query.owner);
        this.isViewedByOwner = query.is_viewed_by_owner;
        this.type = query.type;

        if (query.official_certificate_metadata) {
            this.officialCertificateMetadata = new OfficialCertificateMetadata(query.official_certificate_metadata);
        }
        if (query.created_at) {
            this.createdAt = moment(query.created_at);
        }
        if (query.final_grade) {
            this.finalGrade = new FinalGrade(query.final_grade, true);
        }
        if (query.filename) {
            this.filename = query.filename;
        }
    }
}
