import { ICreateEntityFailureActionPayload } from 'app/projects/entity/src/lib/interfaces/create-entity-failure-payload.interface';
import { IFetchEntityFailureActionPayload } from 'app/projects/entity/src/lib/interfaces/fetch-entity-failure-payload.interface';
import { IUpdateEntityFailureActionPayload } from 'app/projects/entity/src/lib/interfaces/update-entity-failure-payload.interface';
import { AudienceWidget } from '../models/audience-widget.model';
import { MaxBrainAudience } from '../models/audience.model';

export class SetAudience {
    static readonly type = '[Audience] SetAudience';

    constructor(public payload: MaxBrainAudience) {}
}

export class UnsetAudience {
    static readonly type = '[Audience] UnsetAudience';
}

export class FetchAudience {
    static readonly type = '[Audience] FetchAudience';

    constructor(public payload: string) {}
}

export class FetchAudienceSuccess {
    static readonly type = '[Audience] FetchAudienceSuccess';

    constructor(public payload: MaxBrainAudience) {}
}

export class FetchAudienceFailure {
    static readonly type = '[Audience] FetchAudienceFailure';

    constructor(public payload: IFetchEntityFailureActionPayload) {}
}

export class CreateAudience {
    static readonly type = '[Audience] CreateAudience';

    constructor(public payload: MaxBrainAudience) {}
}

export class CreateAudienceSuccess {
    static readonly type = '[Audience] CreateAudienceSuccess';

    constructor(public payload: MaxBrainAudience) {}
}

export class CreateAudienceFailure {
    static readonly type = '[Audience] CreateAudienceFailure';

    constructor(public payload: ICreateEntityFailureActionPayload) {}
}

export class UpdateAudience {
    static readonly type = '[Audience] UpdateAudience';

    constructor(public payload: MaxBrainAudience) {}
}

export class UpdateAudienceSuccess {
    static readonly type = '[Audience] UpdateAudienceSuccess';

    constructor(public payload: MaxBrainAudience) {}
}

export class UpdateAudienceFailure {
    static readonly type = '[Audience] UpdateAudienceFailure';

    constructor(public payload: IUpdateEntityFailureActionPayload) {}
}

export class MoveToAudience {
    static readonly type = '[Audience] MoveToAudience';

    constructor(
        public payload: {
            current: MaxBrainAudience;
            target: MaxBrainAudience;
        }
    ) {}
}

export class MoveToAudienceSuccess {
    static readonly type = '[Audience] MoveToAudienceSuccess';

    constructor(public payload: MaxBrainAudience) {}
}

export class MoveToAudienceFailure {
    static readonly type = '[Audience] MoveToAudienceFailure';

    constructor(public payload: IUpdateEntityFailureActionPayload) {}
}

export class DeleteAudience {
    static readonly type = '[Audience] DeleteAudience';

    constructor(public payload: string) {}
}

export class DeleteAudienceSuccess {
    static readonly type = '[Audience] DeleteAudienceSuccess';

    constructor(public payload: string) {}
}

export class DeleteAudienceFailure {
    static readonly type = '[Audience] DeleteAudienceFailure';

    constructor(public payload: string) {}
}

export class SetAudiences {
    static readonly type = '[Audience] SetAudiences';

    constructor(public payload: MaxBrainAudience[]) {}
}

export class FetchAudiences {
    static readonly type = '[Audience] FetchAudiences';

    constructor(
        public payload?: {
            search: string;
        }
    ) {}
}

export class FetchAudiencesSuccess {
    static readonly type = '[Audience] FetchAudiencesSuccess';
}

export class FetchAudiencesFailure {
    static readonly type = '[Audience] FetchAudiencesFailure';
}

export class ToggleSelectedAudience {
    static readonly type = '[Audience] ToggleSelectedAudience';

    constructor(public payload: string) {}
}

export class SelectFilteredAudiences {
    static readonly type = '[Audience] SelectFilteredAudiences';
}

export class DeselectSelectedAudiences {
    static readonly type = '[Audience] DeselectSelectedAudiences';
}

export class SearchAudiences {
    static readonly type = '[Audience] SearchAudiences';

    constructor(public payload: string) {}
}

export class FetchAudienceWidgets {
    static readonly type = '[Audience] FetchAudienceWidgets';
}

export class FetchAudienceWidgetsSuccess {
    static readonly type = '[Audience] FetchAudienceWidgetsSuccess';

    constructor(public payload: AudienceWidget[]) {}
}

export class FetchAudienceWidgetsFailure {
    static readonly type = '[Audience] FetchAudienceWidgetsFailure';
}

export class FetchDefaultAudiences {
    static readonly type = '[Audience] FetchDefaultAudiences';

    constructor(
        public payload: {
            pageSize?: number;
            pageIndex?: number;
        }
    ) {}
}

export class FetchDefaultAudiencesSuccess {
    static readonly type = '[Audience] FetchDefaultAudiencesSuccess';

    constructor(public payload: MaxBrainAudience[]) {}
}

export class FetchDefaultAudiencesFailure {
    static readonly type = '[Audience] FetchDefaultAudiencesFailure';

    constructor(public payload: IFetchEntityFailureActionPayload) {}
}
