import { ICreateClasstimeQuizActionPayload } from '../interfaces/create-classtime-quiz.action-payload';

export class CreateClasstimeQuizCommand {
    classtime_question_set_id: string;
    question_set_title: string;
    assessment_link_hidden: boolean;
    shuffle_questions: boolean;
    shuffle_question_options: boolean;
    passing_percentage: number;
    mark_completed_on_fail: boolean;
    retryable: boolean;
    minimun_percentage: boolean;
    hide_feedback_mode: boolean;
    show_score_to_participants: boolean;
    max_attempts?: number;

    constructor(entity: ICreateClasstimeQuizActionPayload) {
        if (!entity) {
            throw new Error('Can not create QuizSectionCommand when no input is provided!');
        }

        this.classtime_question_set_id = entity.classtimeQuestionSetId;
        this.question_set_title = entity.questionSetTitle;
        this.assessment_link_hidden = entity.isAssessmentLinkHidden;
        this.shuffle_questions = entity.shuffleQuestions;
        this.shuffle_question_options = entity.shuffleQuestionOptions;
        this.passing_percentage = parseInt(entity.passingPercentage);
        this.mark_completed_on_fail = entity.markCompletedOnFail;
        this.retryable = entity.retryable;
        this.minimun_percentage = entity.minimunPercentage;
        this.hide_feedback_mode = entity.hideFeedbackMode;
        this.show_score_to_participants = entity.showScoreToParticipants;
        this.max_attempts = entity.maxAttempts;
    }
}
