import { Injectable } from '@angular/core';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { NgxsActionHelper } from 'app/projects/core/src/lib/services/action.helper';
import { race } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
    AddUsersToModulesAsParticipantsFailure,
    AddUsersToModulesAsParticipantsSuccess,
    FetchResponsibleRoles,
    FetchResponsibleRolesFailure,
    FetchResponsibleRolesSuccess,
    RemoveSelfAsParticipant,
    RemoveUsersFromModulesAsParticipantsFailure,
    RemoveUsersFromModulesAsParticipantsSuccess,
} from '../actions';
import { AddSelfAsParticipant } from '../actions/index';
import { ModuleUser } from '../models/module-user.model';
import { ModuleUserState } from '../models/module-user.state';
import { ResponsibleRole } from '../models/responsible-role.model';
import { ModuleUserApiService } from './module-user.api-service';

@Injectable()
export class ModuleUserService {
    constructor(private _action$: Actions, private _store: Store, private _actionHelper: NgxsActionHelper, private _moduleUserApiService: ModuleUserApiService) {}

    getModuleUsers(): ModuleUser[] {
        return this._store.selectSnapshot(ModuleUserState.getEntities);
    }

    async fetchResponsibleRoles(): Promise<ResponsibleRole[]> {
        this._store.dispatch(new FetchResponsibleRoles());

        return race(
            this._action$.pipe(
                ofActionDispatched(FetchResponsibleRolesSuccess),
                map((action: FetchResponsibleRolesSuccess) => action.payload)
            ),
            this._action$.pipe(
                ofActionDispatched(FetchResponsibleRolesFailure),
                map((action: FetchResponsibleRolesFailure) => null)
            )
        )
            .pipe(take(1))
            .toPromise();
    }

    async addSelfAsParticipant(moduleId: string): Promise<boolean> {
        this._store.dispatch(new AddSelfAsParticipant(moduleId));

        return await this._actionHelper.race(AddUsersToModulesAsParticipantsSuccess, AddUsersToModulesAsParticipantsFailure).toPromise();
    }

    async removeSelfAsParticipant(moduleId: string): Promise<boolean> {
        this._store.dispatch(new RemoveSelfAsParticipant(moduleId));

        return await this._actionHelper.race(RemoveUsersFromModulesAsParticipantsSuccess, RemoveUsersFromModulesAsParticipantsFailure).toPromise();
    }
}
