import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MaxBrainSharedModule } from 'app/projects/shared/src/lib/shared.module';
import { AudienceApiService } from './services/audience.api-service';
import { AudienceService } from './services/audience.service';

@NgModule({
    imports: [MaxBrainSharedModule, MatChipsModule, MatIconModule, MatToolbarModule, MatFormFieldModule, MatCardModule, MatButtonModule, MatInputModule],
    providers: [AudienceApiService, AudienceService],
    declarations: [],
    entryComponents: [],
})
export class AudienceModule {
    static forRoot(): ModuleWithProviders<AudienceModule> {
        return {
            ngModule: AudienceModule,
            providers: [AudienceApiService],
        };
    }
}
