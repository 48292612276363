import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'isLargeScreen' })
export class IsLargeScreenPipe implements PipeTransform {
    constructor() {}

    transform(): boolean {
        const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        if (width > 1080) {
            return true;
        } else {
            return false;
        }
    }
}
