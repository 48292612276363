import { ToDo } from 'app/library/to-do/models/to-do.model';
import { IUserToDoQuery } from '../interfaces/user-to-do.query.interface';

export class UserToDo {
    toDos: ToDo[];
    totalCountOfOpenToDos: number;
    totalCountOfCompletedToDos: number;
    totalCountOfNewToDos: number;
    totalCountOfToDosThatRequireAction: number;
    totalCount: number;
    hasOverdueToDo: boolean;
    hasNewToDo: boolean;
    hasNotVisibleNewToDo: boolean;
    hasNotVisibleOverdue: boolean;

    constructor(query: IUserToDoQuery) {
        this.toDos = query.to_dos.map((toDo) => new ToDo(toDo));
        this.totalCountOfOpenToDos = query.total_count_of_open_to_dos;
        this.totalCountOfCompletedToDos = query.total_count_of_completed_to_dos;
        this.totalCountOfNewToDos = query.total_count_of_new_to_dos;
        this.totalCountOfToDosThatRequireAction = query.total_count_of_to_dos_that_require_action;
        this.totalCount = query.total_count;
        this.hasOverdueToDo = this.toDos.some((todo) => todo.isOverdue);
        this.hasNewToDo = this.toDos.some((todo) => !todo.isViewedByAssignee);
        this.hasNotVisibleNewToDo = this.toDos.slice(2).some((todo) => todo.isViewedByAssignee);
        this.hasNotVisibleOverdue = this.toDos.slice(2).some((todo) => todo.isOverdue);
    }
}
