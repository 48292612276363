import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IManageAudienceUserActionPayload } from 'app/library/audience-users/interfaces/audience-user-action.action-payload.interface';
import { IEnvironment } from 'app/projects/core/src/lib/interfaces/environment.interface';
import { MAXBRAIN_ENVIRONMENT } from 'app/projects/core/src/lib/services/environment.token';
import { EntityService } from 'app/projects/entity/src/lib/services/entity.service';
import { IPaginatedResponse } from 'app/projects/subentity/src/lib/interfaces/paginated-response.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAudienceUserQuery } from '../interfaces/audience-user.query.interface';
import { IManageAudienceUsersActionPayload } from '../interfaces/audience-users-action.action-payload.interface';
import { IManagePutAudienceUsersActionPayload } from '../interfaces/audience-users-put-action.interface';
import { AudienceUserCommand } from '../models/audience-user.command.model';
import { AudienceUserExtended } from '../models/audience-user.extended.model';
import { AudienceUser } from '../models/audience-user.model';

@Injectable()
export class AudienceUserApiService extends EntityService<IAudienceUserQuery, AudienceUserCommand> {
    constructor(http: HttpClient, @Inject(MAXBRAIN_ENVIRONMENT) environment: IEnvironment) {
        super(http, environment.apiUrl, 'audiences-users');
    }

    getAll(): Observable<AudienceUser[]> {
        return this._getAll().pipe(map((queries) => queries.map((query) => new AudienceUserExtended(query))));
    }

    read(id: string): Observable<AudienceUser> {
        return this._read(id).pipe(map((query) => new AudienceUserExtended(query)));
    }

    update(entity: AudienceUser): Observable<AudienceUser> {
        const data = new AudienceUserCommand(entity);

        return this._update(entity.id, data).pipe(map((query) => new AudienceUserExtended(query)));
    }

    getAllByAudienceId(moduleId: string): Observable<AudienceUser[]> {
        return this.http
            .get<IAudienceUserQuery[]>(`${this.apiUrl}/${this.entities}?audience=${moduleId}`)
            .pipe(map((queries) => queries.map((query) => new AudienceUserExtended(query))));
    }

    getAudiencesByUserId(userId: string, pageSize = 10, pageIndex = 1): Observable<IPaginatedResponse<IAudienceUserQuery>> {
        let params = new HttpParams().set('page', pageIndex.toString()).set('limit', pageSize.toString());

        return this.http.get<IPaginatedResponse<IAudienceUserQuery>>(`${this.apiUrl}/engage/audiences-users?user=${userId}`, { params });
    }

    addUsersToAudience({ audiences, users }: IManageAudienceUserActionPayload): Observable<{
        newlyAssigned: AudienceUserExtended[];
        alreadyInChildren: AudienceUserExtended[];
        alreadyAssigned: AudienceUserExtended[];
    }> {
        const data = {
            audiences: audiences,
            users: users,
        };

        return this.http.post<IManagePutAudienceUsersActionPayload>(`${this.apiUrl}/engage/audiences-users`, data).pipe(
            map((queries) => {
                return {
                    newlyAssigned: queries.newly_assigned.map((query) => new AudienceUserExtended(query)),
                    alreadyInChildren: queries.newly_assigned.map((query) => new AudienceUserExtended(query)),
                    alreadyAssigned: queries.already_assigned.map((query) => new AudienceUserExtended(query)),
                };
            })
        );
    }

    assignUserToAudience({ audiences, users }: IManageAudienceUserActionPayload): Observable<{
        newlyAssigned: AudienceUserExtended[];
        alreadyInChildren: AudienceUserExtended[];
        alreadyAssigned: AudienceUserExtended[];
    }> {
        const data = {
            audiences: audiences,
            users: users,
        };

        return this.http.post<IManagePutAudienceUsersActionPayload>(`${this.apiUrl}/engage/audiences-users`, data).pipe(
            map((queries) => {
                return {
                    newlyAssigned: queries.newly_assigned.map((query) => new AudienceUserExtended(query)),
                    alreadyInChildren: queries.newly_assigned.map((query) => new AudienceUserExtended(query)),
                    alreadyAssigned: queries.already_assigned.map((query) => new AudienceUserExtended(query)),
                };
            })
        );
    }

    removeUsersFromAudiences({ audienceUsers }: { audienceUsers: [{ id: string }] }): Observable<void> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            body: {
                audience_users: audienceUsers,
            },
        };

        return this.http.delete<void>(`${this.apiUrl}/engage/audiences-users`, options);
    }

    moveUsersFromAudience({ source, target, selectedUserIds }: IManageAudienceUsersActionPayload): Observable<AudienceUser[]> {
        const data = {
            source_audience: source,
            target_audience: target,
            audience_users: selectedUserIds,
        };

        return this.http.put<AudienceUser[]>(`${this.apiUrl}/engage/audiences-users/move`, data);
    }
}
