import { CompletionRule } from 'app/library/course-offering/enums/completion-rule.enum';
import { IAutomaticallyBySetRules } from '../interfaces/automatically-by-set-rules.interface';

// @dynamic
export class AutomaticallyBySetRules {
    static all: IAutomaticallyBySetRules[] = [
        {
            translationLabel: 'PROGRAMS.COMPLETION_RULE.BY_SUCCESSFULLY_COMPLETING_ALL_COURSES',
            value: CompletionRule.AUTO_COMPLETE_ALL_COURSES,
            isSelected: false,
        },
        {
            translationLabel: 'PROGRAMS.COMPLETION_RULE.BY_REACHING_PROGRAM_END_DATE',
            value: CompletionRule.AUTO_END_DATE_REACHED,
            isSelected: false,
        },
        {
            translationLabel: 'PROGRAMS.COMPLETION_RULE.BY_RECIEVING_CERTIFICATE',
            value: CompletionRule.AUTO_RECEIVE_CERTIFICATE,
            isSelected: false,
        },
        {
            translationLabel: 'PROGRAMS.COMPLETION_RULE.BY_ACHIEVING_A_PASSING_GRADE',
            value: CompletionRule.AUTO_ACHIEVE_PASSING_GRADE,
            isSelected: false,
        },
        {
            translationLabel: 'PROGRAM_OFFERING.COMPLETION_RULE.ATTENDANCE.BY_REACING_MINIMUM_ATTENDANCE_REQUIREMENT',
            value: CompletionRule.AUTO_COMPLETE_ALL_COURSES_AND_MIN_ATTENDANCE_REACHED,
            isSelected: false,
        },
        {
            translationLabel: 'PROGRAM_OFFERING.COMPLETION_RULE.ATTENDANCE.BY_REACING_MINIMUM_ATTENDANCE_REQUIREMENT',
            value: CompletionRule.AUTO_END_DATE_REACHED_AND_MIN_ATTENDANCE_REACHED,
            isSelected: false,
        },
        {
            translationLabel: 'PROGRAM_OFFERING.COMPLETION_RULE.BY_COMPLETING_SECTIONS',
            value: CompletionRule.SECTIONS,
            isSelected: false,
        },
    ];
}
