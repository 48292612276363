import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-retry-saving-dialog',
    templateUrl: './retry-saving-dialog.component.html',
    styleUrls: ['./retry-saving-dialog.component.scss'],
})
export class RetrySavingDialogComponent implements OnInit {
    @Input()
    label: string;

    @Output()
    save = new EventEmitter<string>();

    constructor(public dialogRef: MatDialogRef<RetrySavingDialogComponent>) {}

    ngOnInit(): void {}
}
