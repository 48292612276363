import { IAudienceWidgetQuery } from '../interfaces/audience-widget.query';

export class AudienceWidget {
    experianceName: string;
    id: string;
    name: string;
    description: string;
    type: string;
    featuredStreamId: string;
    isWidgetEmpty?: boolean;

    constructor(query: IAudienceWidgetQuery) {
        this.experianceName = query.experience_name;
        this.id = query.id.toString();
        this.name = query.name;
        this.description = query.description;
        this.type = query.type;
        this.featuredStreamId = query.featured_stream_id.toString();
    }
}
