import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MaxBrainSharedModule } from 'app/projects/shared/src/lib/shared.module';
import { MaxBrainModuleUserModule } from '../module-user/module-user.module';
import { AudienceUsersApiService } from './services/audience-users.api-service';
import { AudienceUsersService } from './services/audience-users.service';

@NgModule({
    imports: [
        MaxBrainSharedModule,
        MatCheckboxModule,
        MatMenuModule,
        MatTableModule,
        MatListModule,
        MatCardModule,
        MatIconModule,
        MatFormFieldModule,
        MatToolbarModule,
        MatAutocompleteModule,
        MatInputModule,
        MatButtonModule,
        MatSortModule,
        MaxBrainModuleUserModule,
    ],
    declarations: [],
    entryComponents: [],
    exports: [],
    providers: [AudienceUsersService],
})
export class MaxBrainAudienceUsersModule {
    static forRoot(): ModuleWithProviders<MaxBrainAudienceUsersModule> {
        return {
            ngModule: MaxBrainAudienceUsersModule,
            providers: [AudienceUsersApiService],
        };
    }
}
