import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { UnsubscribeOnDestroy } from 'app/projects/core/src/lib/models/unsubscribe-on-destroy';
import { FuseConfigService } from 'app/projects/fuse/src/lib/services/config.service';
import { User } from 'app/projects/user/src/lib/models/user';
import { UserState } from 'app/projects/user/src/lib/models/user.state';
import { ConversationOpenedFromContactCard, TalkService } from 'app/services/talk.service';
import { takeUntil } from 'rxjs/operators';
import { UserDetalisCardService } from './user-details-card.service';

@Component({
    selector: 'app-user-details-card',
    templateUrl: './user-details-card.component.html',
    styleUrls: ['./user-details-card.component.scss'],
})
export class UserDetailsCardComponent extends UnsubscribeOnDestroy implements OnInit, AfterViewInit {
    @Input()
    user: User;

    @Input()
    showEmail = true;

    @Input()
    target: HTMLElement;

    @Input()
    openedFrom: ConversationOpenedFromContactCard;

    @Input()
    isTitleClickable = true;

    @Input()
    wideBox = false;

    @Output()
    goToAction = new EventEmitter();

    @ViewChild('card')
    card: ElementRef;

    @ViewChild('content')
    content: ElementRef;

    hide = true;
    eventName = 'mouseenter';
    fuseConfig: any;
    profileId = this._store.selectSnapshot(UserState).me;
    isCursorOnCard = false;
    isCursorOnTarget = false;
    cardTopValue: number;
    cardLeftValue: number;
    isCardVisible: boolean = false;
    isKmpAppActive = false;

    mousePointerX: number;
    mousePointerY: number;

    @HostListener('document:mousemove', ['$event']) onMouseMove(event) {
        this.mousePointerX = event.clientX;
        this.mousePointerY = event.clientY;
    }

    private _toggle(): void {
        this.service.toggle(this);
    }

    constructor(
        private _renderer: Renderer2,
        private service: UserDetalisCardService,
        private _talkService: TalkService,
        private _fuseConfigService: FuseConfigService,
        private _store: Store
    ) {
        super();

        // Subscribe to config changes
        this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
            this.fuseConfig = config;
        });
    }

    ngOnInit(): void {
        const mobileAppValue = localStorage.getItem('kmpMobileApp');

        if (mobileAppValue === 'ios' || mobileAppValue === 'android') {
            this.isKmpAppActive = true;
        }

        if (!this.isKmpAppActive) {
            this._renderer.listen(this.target, this.eventName, (event) => {
                this.isCursorOnTarget = true;

                const targetBottomPosition = this.target.getBoundingClientRect().bottom;
                const targetRightPosition = this.target.getBoundingClientRect().right;
                const targetLeftPosition = this.target.getBoundingClientRect().left;

                setTimeout(() => {
                    // Calculates distance between current mouse cursor position X and position X where we entered this target
                    let differenceX = this.mousePointerX - event.clientX;

                    // Converting negative values to positive - for easier comparison below
                    if (differenceX < 0) {
                        differenceX += differenceX * -1 * 2;
                    }

                    // Calculates distance between current mouse cursor position Y and position Y where we entered this target
                    let differenceY = this.mousePointerY - event.clientY;

                    // Converting negative values to positive - for easier comparison below
                    if (differenceY < 0) {
                        differenceY += differenceY * -1 * 2;
                    }

                    // We want to toggle card visibility only if it's already hidden and if difference from above is less than (1)30(x) or 30(y) px. We don't want that user sees a card if he has accidently hovered over the target
                    if (this.hide && differenceX < (this.wideBox ? 130 : 30) && differenceY < 30) {
                        this._toggle();

                        setTimeout(() => {
                            const cardHeight = this.content.nativeElement.clientHeight;
                            const cardWidth = this.content.nativeElement.clientWidth;
                            this.cardLeftValue = targetLeftPosition + 20;
                            this.cardTopValue = targetBottomPosition + 10;

                            // Show user details card above target if card is too low on the screen
                            if (targetBottomPosition + cardHeight > window.scrollY + window.innerHeight) {
                                this.cardTopValue = targetBottomPosition - cardHeight - 35;
                            }

                            // Show user details card on the left if card is too right on the screen
                            if (targetRightPosition + cardWidth > window.scrollX + window.innerWidth) {
                                this.cardLeftValue = targetRightPosition - cardWidth - 25;
                            }

                            this.isCardVisible = true;
                        }, 0);
                    }
                }, 400);
            });

            this._renderer.listen(this.target, 'mouseleave', (event) => {
                this.isCursorOnTarget = false;

                setTimeout(() => {
                    if (!this.isCursorOnCard && !this.hide) {
                        this._toggle();
                    }
                }, 350);
            });
        }
    }

    ngAfterViewInit(): void {
        this._renderer.listen(this.card.nativeElement, 'mouseleave', (event) => {
            this.isCursorOnCard = false;

            setTimeout(() => {
                if (!this.isCursorOnTarget && !this.hide) {
                    this._toggle();
                }
            }, 350);
        });

        this._renderer.listen(this.card.nativeElement, 'mouseenter', () => {
            this.isCursorOnCard = true;
        });
    }

    goTo() {
        this.goToAction.emit();
    }

    async startConversation(): Promise<void> {
        if (!this.hide) {
            this._toggle();
        }
        await this._talkService.startConversationByUser(this.user, this.openedFrom);
    }
}
