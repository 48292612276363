import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
    CreateAudienceSuccess,
    DeleteAudience,
    FetchDefaultAudiences,
    FetchDefaultAudiencesFailure,
    FetchDefaultAudiencesSuccess,
    SetAudiences,
} from 'app/library/audience/actions';
import { AddAudiences } from 'app/library/audience/actions/add-entities.action';
import { MaxBrainAudience } from 'app/library/audience/models/audience.model';
import { AudienceState } from 'app/library/audience/models/audience.state';
import { SubentityState } from 'app/projects/subentity/src/lib/models/subentity.state';
import { map } from 'rxjs/operators';
import { DeselectSelectedAudiences, SearchAudiences, SelectFilteredAudiences, ToggleSelectedAudience } from '../actions';
import { AudienceApiService } from '../services/audience.api-service';
import { AudiencesStateModel } from './audiences.state-model';

@State<AudiencesStateModel>({
    name: 'audiences',
    defaults: new AudiencesStateModel(),
})
@Injectable()
export class AudiencesState extends SubentityState<MaxBrainAudience> {
    @Selector([AudienceState.getEntities])
    static getFilteredEntities(state: AudiencesStateModel, audiences: MaxBrainAudience[]): MaxBrainAudience[] {
        if (!audiences) {
            return null;
        }

        return audiences
            .filter((item) => state.filtered.indexOf(item.id) !== -1)
            .map((item) => {
                return [state.list.indexOf(item.id), item];
            })
            .sort((a: [number, MaxBrainAudience], b: [number, MaxBrainAudience]) => {
                return a[0] > b[0] ? 1 : b[0] > a[0] ? -1 : 0;
            })
            .map((x: [number, MaxBrainAudience]) => x[1]);
    }

    @Selector()
    static getSelectedEntityIds(state: AudiencesStateModel): string[] {
        return state.selected;
    }

    @Selector()
    static getSearchTerm(state: AudiencesStateModel): string {
        return state.searchTerm;
    }

    @Selector()
    static getDefaultAudiences(state: AudiencesStateModel): MaxBrainAudience[] {
        return state.defaultAudiencesList;
    }

    @Selector()
    static getDefaultAudiencesTotalCount(state: AudiencesStateModel): number {
        return state.defaultAudienceTotalCount;
    }

    constructor(private store: Store, private _audienceService: AudienceApiService) {
        super(null, 0);

        this._propstoSearch = [...MaxBrainAudience.props_to_search];
    }

    @Action(AddAudiences)
    addEntities(ctx: StateContext<AudiencesStateModel>, action: AddAudiences): void {
        const audiences = this.store.selectSnapshot(AudienceState.getEntities) || [];

        this._patchSubentities(ctx, { payload: { subentityIds: action.payload.map((entity) => entity.id) } }, audiences);
    }

    @Action(CreateAudienceSuccess)
    createEntity(ctx: StateContext<AudiencesStateModel>, action: CreateAudienceSuccess): void {
        const audiences = this.store.selectSnapshot(AudienceState.getEntities) || [];

        this._patchSubentities(ctx, { payload: { subentityIds: [action.payload.id] } }, audiences);
    }

    @Action(DeleteAudience)
    deleteEntity({ getState, patchState }: StateContext<AudiencesStateModel>, { payload }: DeleteAudience): void {
        const state = getState();

        patchState({
            list: [...state.list.filter((subentityId) => subentityId !== payload)],
            selected: [...state.selected.filter((subentityId) => subentityId !== payload)],
            filtered: [...state.filtered.filter((subentityId) => subentityId !== payload)],
        });
    }

    @Action(SetAudiences)
    setEntities(ctx: StateContext<AudiencesStateModel>, action: SetAudiences): void {
        this._setSubentities(ctx, action, null);
    }

    @Action(ToggleSelectedAudience)
    toggleSelect(ctx: StateContext<AudiencesStateModel>, action: ToggleSelectedAudience): void {
        this._toggleSelectedSubentity(ctx, action);
    }

    @Action(SelectFilteredAudiences)
    selectAll(ctx: StateContext<AudiencesStateModel>): void {
        this._selectFilteredSubentities(ctx);
    }

    @Action(DeselectSelectedAudiences)
    deselectAll(ctx: StateContext<AudiencesStateModel>): void {
        this._deselectSelectedSubentities(ctx);
    }

    @Action(SearchAudiences)
    search(ctx: StateContext<AudiencesStateModel>, action: SearchAudiences): void {
        const audiences = this.store.selectSnapshot(AudienceState.getEntities);

        this._searchSubentities(ctx, action, audiences);
    }

    @Action(FetchDefaultAudiences)
    fetchDefaultAudience(ctx: StateContext<AudiencesStateModel>, action: FetchDefaultAudiences): void {
        this._audienceService
            .getDefaultAudiences(action.payload.pageSize, action.payload.pageIndex)
            .pipe(
                map((data) => {
                    let audiences = data.data.map((query) => new MaxBrainAudience(query));

                    ctx.patchState({
                        defaultAudienceTotalCount: data.pagination.number_of_results,
                        defaultAudiencesList: audiences,
                    });

                    return audiences;
                })
            )
            .subscribe(
                (entities) => {
                    ctx.dispatch(new FetchDefaultAudiencesSuccess(entities));
                },
                (response: HttpErrorResponse) => {
                    ctx.dispatch(new FetchDefaultAudiencesFailure(response.error));
                }
            );
    }
}
