import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ExtendedAttributeFilterInterface } from 'app/library/all-users/interfaces/extended-attribute-filer.interface';
import { ICertificateRecordQuery } from 'app/library/certificate-record/interfaces/certificate-record.query.interface';
import { CertificateRecord } from 'app/library/certificate-record/models/certificate-record';
import { IEventQuery } from 'app/library/event/interfaces/event.query.interface';
import { MaxBrainEvent } from 'app/library/event/models/event.model';
import { MaxBrainAuthState } from 'app/projects/auth/src/lib/models/auth.state';
import { IEnvironment } from 'app/projects/core/src/lib/interfaces/environment.interface';
import { MAXBRAIN_ENVIRONMENT } from 'app/projects/core/src/lib/services/environment.token';
import { MaxBrainUtils } from 'app/projects/core/src/lib/utils';
import { IEntityApiService } from 'app/projects/entity/src/lib/interfaces/entity.service.interface';
import { EntityService } from 'app/projects/entity/src/lib/services/entity.service';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, map, take, tap } from 'rxjs/operators';
import { WelcomeTourStatus } from '../enums/welcome-tour-status.enum';
import { ISocialNetworkActionPayload } from '../interfaces/social-network.action-payload.interface';
import { ISocialNetworkQuery } from '../interfaces/social-network.query.interface';
import { IUserAdditionalEmailQuery } from '../interfaces/user-additional-emails.query.interface';
import { IUserLearningProgressQuery } from '../interfaces/user-learning-progress.query.interface';
import { IUserOnlineStateQueryInterface } from '../interfaces/user-online-state-query.interface';
import { IUserResetPasswordUrl } from '../interfaces/user-reset-password-url.interface';
import { IUserQuery } from '../interfaces/user.query.interface';
import { IUsersImportQuery } from '../interfaces/users-import.query.interface';
import { PatchProfileCommand } from '../models/patch-profile.command';
import { SocialNetworkCommand } from '../models/social-network.command';
import { SocialNetwork } from '../models/social-network.model';
import { User } from '../models/user';
import { UserAdditionalEmail } from '../models/user-additional-email';
import { UserLearningProgress } from '../models/user-learning-progress.model';
import { UserOnlineState } from '../models/user-online-state.model';
import { UserPermissionsCommand } from '../models/user-permissions.command';
import { UserProfilePictureCommand } from '../models/user-profile-picture.command';
import { UserResetPasswordUrl } from '../models/user-reset-password-url.model';
import { UserStatusBulkCommand } from '../models/user-status-bulk.command';
import { UserStatusCommand } from '../models/user-status.command';
import { UserCommand } from '../models/user.command';
import { UsersImportCommand } from '../models/users-import.command';
import { UsersImport } from '../models/users-import.model';
import { UserToDosStatistics } from '../models/user-to-dos-statistics.model';
import { IUserToDosStatisticsQuery } from '../interfaces/user-to-dos-statistics.query.interface';
import { IUserToDoQuery } from '../interfaces/user-to-do.query.interface';
import { UserToDo } from '../models/user-to-do.model';

interface IEventsAndTotalCount {
    events: IEventQuery[];
    total_count_of_upcoming_events: number;
}

interface ICertificatesAndTotalCount {
    certificates: ICertificateRecordQuery[];
    total_count_of_certificates_that_require_action: number;
    new_count: number;
    total_count: number;
}

@Injectable()
export class UserApiService extends EntityService<IUserQuery, UserCommand> implements IEntityApiService<User> {
    accessToken: string;
    headers: {
        Authorization: string;
    };

    private async _getToken(): Promise<void> {
        this.accessToken = await this._store
            .select(MaxBrainAuthState.getAccessToken)
            .pipe(
                filter((x) => !!x),
                take(1)
            )
            .toPromise();

        this.headers = {
            Authorization: `Bearer ${this.accessToken}`,
        };
    }

    constructor(http: HttpClient, @Inject(MAXBRAIN_ENVIRONMENT) private environment: IEnvironment, private _store: Store) {
        super(http, environment.apiUrl, 'users');
    }

    me(): Observable<User> {
        return this.http.get<IUserQuery>(`${this.apiUrl}/me`).pipe(map((query) => new User(query)));
    }

    getAll(): Observable<User[]> {
        return this._getAll().pipe(map((queries: IUserQuery[]) => queries.map((query) => new User(query))));
    }

    create(entity: User): Observable<User> {
        const data = new UserCommand(entity);

        return this._create(data).pipe(map((query) => new User(query)));
    }

    read(id: string): Observable<User> {
        return this._read(id).pipe(map((query) => new User(query)));
    }

    update(entity: User): Observable<User> {
        const data = new UserCommand(entity);

        return this._update(entity.id, data).pipe(map((query) => new User(query)));
    }

    getWelcomeTourContent(): Promise<{ text: string }> {
        return this.http.get<{ text: string }>(`${this.apiUrl}/welcome-tour/content`).toPromise();
    }

    getWelcomeTourStatus(): Promise<{ status: number }> {
        return this.http.get<{ status: number }>(`${this.apiUrl}/welcome-tour/status`).toPromise();
    }

    updateWelcomeTourStatus(status: WelcomeTourStatus): Promise<{ status: number }> {
        return this.http.patch<{ status: number }>(`${this.apiUrl}/welcome-tour/status`, { status }).toPromise();
    }

    updatePermissions(entityId: string, permissions: string[]): Observable<User> {
        const data = new UserPermissionsCommand(permissions);

        return this.http.patch<IUserQuery>(`${this.apiUrl}/${this.entities}/${entityId}/permission`, data).pipe(map((query) => new User(query)));
    }

    updateProfile(data: PatchProfileCommand): Observable<User> {
        return this.http.patch<IUserQuery>(`${this.apiUrl}/profile`, data).pipe(map((query) => new User(query)));
    }

    updateProfilePicture(id: string, file: File): Observable<User> {
        const data = new UserProfilePictureCommand(file).formData;

        return this.http.post<IUserQuery>(`${this.apiUrl}/${this.entities}/${id}/profile-picture`, data).pipe(map((query) => new User(query)));
    }

    deleteProfilePicture(id: string): Observable<any> {
        return this.http.delete<IUserQuery[]>(`${this.apiUrl}/${this.entities}/${id}/profile-picture`);
    }

    updateStatus(entity: User): Observable<User> {
        const data = new UserStatusCommand(entity);

        return this.http.patch<IUserQuery>(`${this.apiUrl}/${this.entities}/${entity.id}/status`, data).pipe(map((query) => new User(query)));
    }

    updateStatusBulk(entityIds: string[], status: string): Observable<User[]> {
        const data = new UserStatusBulkCommand(entityIds, status);

        return this.http.post<IUserQuery[]>(`${this.apiUrl}/${this.entities}/status/bulk`, data).pipe(map((queries) => queries.map((query) => new User(query))));
    }

    delete(id: string): Observable<string> {
        return this._delete(id);
    }

    addSocialNetwork(payload: ISocialNetworkActionPayload): Observable<SocialNetwork> {
        const data = new SocialNetworkCommand(payload.user, payload.value.indexOf('http') === 0 ? payload.value : `https://${payload.value}`);

        return this.http.post<ISocialNetworkQuery>(`${this.apiUrl}/social-network`, data).pipe(map((query) => new SocialNetwork(query)));
    }

    addAdditionalEmail(email: string): Observable<void> {
        return this.http.post<void>(`${this.apiUrl}/users/additional-emails`, { email: email });
    }

    addAdditionalEmailToUser(email: string, userId: string): Observable<void> {
        return this.http.post<void>(`${this.apiUrl}/admin/users/additional-emails`, { email: email, user_id: userId });
    }

    getUserAdditionalEmails(userId: string, isAdditional?: boolean, isVerified?: boolean): Observable<UserAdditionalEmail[]> {
        let params = new HttpParams();

        if (isAdditional) {
            params = params.set('filter[isAdditional]', isAdditional);
        }

        if (isVerified) {
            params = params.set('filter[isVerified]', isVerified);
        }

        return this.http
            .get<IUserAdditionalEmailQuery[]>(`${this.apiUrl}/users/${userId}/emails`, { params })
            .pipe(map((queries) => queries.map((query) => new UserAdditionalEmail(query))));
    }

    deleteUserAdditionalEmail(email: string): Observable<void> {
        return this.http.delete<void>(`${this.apiUrl}/users/additional-emails/${email}`);
    }

    deleteSocialNetwork(id: string): Observable<void> {
        return this.http.delete<void>(`${this.apiUrl}/social-network/${id}`);
    }

    downloadUsersList(fileName: string): Observable<Blob> {
        return this.http.get(`${this.apiUrl}/${this.entities}/import-template`, { responseType: 'blob' }).pipe(tap((file) => MaxBrainUtils.downloadFile(file, fileName)));
    }

    downloadAllUsersList(
        search: string,
        moduleIds: string[],
        experienceIds: string[],
        audienceIds: string[],
        teamIds: string[],
        isUnassignedSelected = false,
        isUnassignedSelectedExperiences = false,
        isUnassignedSelectedAudiences = false,
        isUnassignedSelectedTeams = false,
        extendedAttributesFilters: ExtendedAttributeFilterInterface[] = [],
        userRoleIds: string[],
        columns: string[] = []
    ): Observable<Blob> {
        let params = new HttpParams();

        if (search) {
            params = params.set('filter[search]', search);
        }

        if (moduleIds.length) {
            params = params.append('filter[module]', moduleIds.join());
        }

        if (experienceIds.length) {
            params = params.append('filter[experience]', experienceIds.join());
        }

        if (audienceIds.length) {
            params = params.append('filter[audience]', audienceIds.join());
        }

        if (teamIds.length) {
            params = params.append('filter[team]', teamIds.join());
        }

        if (isUnassignedSelected) {
            params = params.set('filter[module]', 'null');
        }

        if (isUnassignedSelectedExperiences) {
            params = params.set('filter[experience]', 'null');
        }

        if (isUnassignedSelectedAudiences) {
            params = params.set('filter[audience]', 'null');
        }

        if (isUnassignedSelectedTeams) {
            params = params.set('filter[team]', 'null');
        }

        if (columns.length) {
            params = params.set('filter[columns]', columns.join());
        }

        if (extendedAttributesFilters.length) {
            let attributeValues = {};

            extendedAttributesFilters.forEach((attr) => {
                if (attr.value) {
                    if (!attributeValues[attr.attributeName]) {
                        attributeValues[attr.attributeName] = [];
                    }

                    attributeValues[attr.attributeName].push(attr.value);
                } else {
                    params = params.set(`filter[${attr.attributeName}][gte]`, attr.rangeValue1);
                    params = params.set(`filter[${attr.attributeName}][lte]`, attr.rangeValue2);
                }
            });

            Object.keys(attributeValues).forEach((key) => {
                params = params.append(`filter[${key}]`, attributeValues[key].join(', '));
            });
        }

        if (userRoleIds.length) {
            params = params.append('filter[userRole]', userRoleIds.join());
        }

        return this.http.get(`${this.apiUrl}/user-list`, { params, responseType: 'blob' }).pipe(
            tap((userListFile) => MaxBrainUtils.downloadFile(userListFile, 'all_users.xlsx')),
            catchError((error) => {
                console.error(error);
                return throwError('Failed to download user list');
            })
        );
    }

    uploadUsersList(file: File, idsModules: string[]): Observable<UsersImport> {
        const data = new UsersImportCommand('file', new File([file], ''), file.name, 'module_ids', idsModules).formData;

        return this.http.post<IUsersImportQuery>(`${this.apiUrl}/user/import`, data).pipe(map((query: IUsersImportQuery) => new UsersImport(query)));
    }

    addPermission(permissions: string[], userId: string): Observable<User> {
        const data = new UserPermissionsCommand(permissions);

        return this.http.post<IUserQuery>(`${this.apiUrl}/users/${userId}/permission`, data).pipe(map((query) => new User(query)));
    }

    removePermission(permissions: string[], userId: string): Observable<User> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            body: new UserPermissionsCommand(permissions),
        };

        return this.http.delete<IUserQuery>(`${this.apiUrl}/users/${userId}/permission`, options).pipe(map((query) => new User(query)));
    }

    claimPermission(userId: string): Observable<User> {
        return this.http.post<IUserQuery>(`${this.apiUrl}/encryption/${userId}/claim-permission`, {}).pipe(map((query) => new User(query)));
    }

    resetPassword(email: string): Observable<User[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'edu-subdomain': localStorage.getItem('subdomain'),
            }),
        };

        return this.http.post<User[]>(`${this.apiUrl}/password-reset`, { email }, httpOptions);
    }

    resetPasswords(id: string[]): Observable<User[]> {
        return this.http.post<User[]>(`${this.apiUrl}/users/password-reset/bulk`, { id });
    }

    resetUserPasswordUrl(email: string): Observable<{ resetUrl: string }> {
        return this.http.post<{ reset_url: string }>(`${this.apiUrl}/users/password-reset-url`, { email }).pipe(map((query) => ({ resetUrl: query.reset_url })));
    }

    resetPasswordUrl(userIds: string[]): Observable<UserResetPasswordUrl[]> {
        return this.http
            .post<IUserResetPasswordUrl[]>(`${this.apiUrl}/users/password-reset-url/bulk`, {
                user_ids: userIds,
            })
            .pipe(map((queries) => queries.map((query) => new UserResetPasswordUrl(query))));
    }

    changeNotificationEmailAddress(id: string, notificationEmail: string): Observable<User> {
        return this.http.patch<IUserQuery>(`${this.apiUrl}/users/${id}/notification-email`, { notification_email: notificationEmail }).pipe(map((query) => new User(query)));
    }

    changeUserEmailAddress(id: string, email: string): Observable<User[]> {
        return this.http.patch<User[]>(`${this.apiUrl}/users/${id}/email`, { email });
    }

    updateLanguage(ids: string[], language: string): Observable<User[]> {
        return this.http.patch<IUserQuery[]>(`${this.apiUrl}/users/language/bulk`, { ids, language }).pipe(map((queries) => queries.map((query) => new User(query))));
    }

    async getUserOnlineState(emails: string[]): Promise<UserOnlineState> {
        const subdomain = localStorage.getItem('subdomain');
        const bodyEmails = emails.map((email) => parseInt(email));

        await this._getToken();

        return this.http
            .post<IUserOnlineStateQueryInterface>(`${this.apiUrl}/services/onlinestate/${subdomain}`, bodyEmails, {
                headers: this.headers,
            })
            .pipe(map((query) => new UserOnlineState(query)))
            .toPromise();
    }

    async getLearningProgress(): Promise<UserLearningProgress> {
        return this.http
            .get<IUserLearningProgressQuery>(`${this.apiUrl}/me/learner-profile/learning-progress`)
            .pipe(map((query) => new UserLearningProgress(query)))
            .toPromise();
    }

    async getLearnerProfileEvents(): Promise<{ events: MaxBrainEvent[]; totalCount: number }> {
        return this.http
            .get<IEventsAndTotalCount>(`${this.apiUrl}/me/learner-profile/events`)
            .pipe(
                map((query) => {
                    return {
                        events: query.events.map((event) => new MaxBrainEvent(event)),
                        totalCount: query.total_count_of_upcoming_events,
                    };
                })
            )
            .toPromise();
    }

    async getLearnerProfileToDos(): Promise<UserToDo> {
        return this.http
            .get<IUserToDoQuery>(`${this.apiUrl}/me/learner-profile/to-dos`)
            .pipe(
                map((query) => {
                    return new UserToDo(query);
                })
            )
            .toPromise();
    }

    async getUserToDoStatistics(userId: string): Promise<UserToDosStatistics> {
        return this.http
            .get<IUserToDosStatisticsQuery>(`${this.apiUrl}/users/${userId}/to-dos/statistics`)
            .pipe(
                map((query) => {
                    return new UserToDosStatistics(query);
                })
            )
            .toPromise();
    }

    async getLearnerProfileCertificates(): Promise<{ certificates: CertificateRecord[]; totalCountRequireAction: number; newCount: number; totalCount: number }> {
        try {
            return await this.http
                .get<ICertificatesAndTotalCount>(`${this.apiUrl}/me/learner-profile/certificates`)
                .pipe(
                    map((query) => ({
                        certificates: query.certificates.map((certificate) => new CertificateRecord(certificate)),
                        totalCountRequireAction: query.total_count_of_certificates_that_require_action,
                        newCount: query.new_count,
                        totalCount: query.total_count,
                    })),
                    catchError((error) => {
                        // Log error for debugging
                        console.error('Error fetching certificates:', error);

                        // Throw a new, more descriptive error to be caught later
                        throw new Error('Failed to fetch learner profile certificates. Please try again later.');
                    })
                )
                .toPromise();
        } catch (error) {
            // Re-throw the error to the calling code
            throw error;
        }
    }
}
