import { MaxBrainAudience } from 'app/library/audience/models/audience.model';
import { User } from 'app/projects/user/src/lib/models/user';
import { IAudienceUserQuery } from '../interfaces/audience-user.query.interface';
import { AudienceUser } from './audience-user.model';

export class AudienceUserExtended extends AudienceUser {
    audience: MaxBrainAudience;
    user: User;

    constructor(audienceUser?: IAudienceUserQuery) {
        super(audienceUser);

        if (audienceUser) {
            this.audience = new MaxBrainAudience(audienceUser.audience);
            this.user = new User(audienceUser.user);
        } else {
            this.audience = null;
            this.user = null;
        }
    }
}
