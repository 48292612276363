import { MaxBrainAudience } from './audience.model';

export class AudienceUpdateCommand {
    name?: string;
    is_default?: boolean;

    constructor(tempAudience: MaxBrainAudience) {
        this.name = tempAudience.name;
        this.is_default = tempAudience.isDefault;
    }
}
