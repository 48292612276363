import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MaxBrainSharedModule } from 'app/projects/shared/src/lib/shared.module';
import { UserDetailsCardComponent } from './user-details-card.component';
import { UserDetalisCardService } from './user-details-card.service';

@NgModule({
    imports: [MaxBrainSharedModule, MatIconModule, MatCardModule, MatButtonModule, MatTooltipModule],
    providers: [UserDetalisCardService],
    declarations: [UserDetailsCardComponent],
    exports: [UserDetailsCardComponent],
})
export class UserDetailsCardModule {}
