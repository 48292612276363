import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { FetchModuleContentNodeSuccess, FetchPrivateContentNodeSuccess } from 'app/library/module-content-nodes/actions';
import { ModuleContentNodesState } from 'app/library/module-content-nodes/models/module-content-nodes.state';
import { SetModuleParticipants } from 'app/library/module-participants/actions';
import { ModuleParticipantsState } from 'app/library/module-participants/models/module-participants.state';
import { UpdateModuleUserSuccess } from 'app/library/module-user/actions';
import { ModuleUserExtended } from 'app/library/module-user/models/module-user.extended.model';
import { ModuleUser } from 'app/library/module-user/models/module-user.model';
import { ModuleUserState } from 'app/library/module-user/models/module-user.state';
import { ModuleApiService } from 'app/library/module/services/module.api-service';
import { ParticipantRegistrationMetric } from 'app/library/participant-registration-metric/models/participant-registration-metric.model';
import { IEntityState } from 'app/projects/entity/src/lib/interfaces/entity.state.interface';
import { EntityState } from 'app/projects/entity/src/lib/models/entity.state';
import { UserState } from 'app/projects/user/src/lib/models/user.state';
import { FinalGradeMetrics } from '../../final-grade/models/final-grade-metric.model';
import { SetModuleManagers } from '../../module-managers/actions';
import { ScormService } from '../../scorm/services/scorm.service';
import {
    AddScheduleToModule,
    ConvertExecutionToModule,
    ConvertExecutionToModuleFailure,
    ConvertExecutionToModuleSuccess,
    ConvertModuleToExecution,
    ConvertModuleToExecutionFailure,
    ConvertModuleToExecutionSuccess,
    CopyELearningModuleToNew,
    CopyModuleToExistingFailure,
    CopyModuleToNewFailure,
    CopyModuleToNewSuccess,
    CreateModule,
    CreateModuleFailure,
    CreateModuleSuccess,
    DeleteModule,
    DeleteModuleFailure,
    DeleteModuleSuccess,
    FetchMinimumAttendance,
    FetchMinimumAttendanceFailure,
    FetchMinimumAttendanceSuccess,
    FetchModule,
    FetchModuleCourseStatus,
    FetchModuleCourseStatusFailure,
    FetchModuleCourseStatusSuccess,
    FetchModuleFailure,
    FetchModuleFinalGradeMetrics,
    FetchModuleFinalGradeMetricsFailure,
    FetchModuleFinalGradeMetricsSuccess,
    FetchModuleImportStatus,
    FetchModuleImportStatusFailure,
    FetchModuleImportStatusSuccess,
    FetchModuleSuccess,
    FetchModules,
    FetchModulesFailure,
    FetchModulesSuccess,
    FetchTenantAdminModuleStats,
    FetchTenantAdminModuleStatsFailure,
    FetchTenantAdminModuleStatsSuccess,
    GetCourseStructureModuleBreadcrumbs,
    GetCourseStructureModuleBreadcrumbsFailure,
    GetCourseStructureModuleBreadcrumbsSuccess,
    GetModuleLaunchCourseUrl,
    GetModuleLaunchCourseUrlFailure,
    GetModuleLaunchCourseUrlSuccess,
    GetModuleParticipantScormRegistrationMetrics,
    GetModuleParticipantScormRegistrationMetricsFailure,
    GetModuleParticipantScormRegistrationMetricsSuccess,
    ImportModule,
    ImportModuleFailure,
    ImportModuleSuccess,
    ModuleSection,
    NotifyAllParticipants,
    NotifyAllParticipantsFailure,
    NotifyAllParticipantsSuccess,
    NotifySelectedParticipants,
    NotifySelectedParticipantsFailure,
    NotifySelectedParticipantsSuccess,
    PatchModuleCourseId,
    RemoveScheduleFromModule,
    SaveNotificationDefaultText,
    SaveNotificationDefaultTextFailure,
    SaveNotificationDefaultTextSuccess,
    SetModule,
    SetModules,
    UnsetModule,
    UnsetModuleParticipantScormRegistrationMetrics,
    UpdateCompletionRule,
    UpdateCompletionRuleFailure,
    UpdateCompletionRuleSuccess,
    UpdateELearningModuleFailure,
    UpdateELearningModuleSuccess,
    UpdateMinimumAttendance,
    UpdateMinimumAttendanceFailure,
    UpdateMinimumAttendanceSuccess,
    UpdateModule,
    UpdateModuleFailure,
    UpdateModuleSection,
    UpdateModuleSectionFailure,
    UpdateModuleSectionSuccess,
    UpdateModuleSuccess,
    UpdateScheduleInModule,
} from '../actions';
import { AddModules } from '../actions/add-entities.action';
import { FetchModuleSlackFailure } from '../actions/fetch-module-slack-failure.action';
import { FetchModuleSlackSuccess } from '../actions/fetch-module-slack-success.action';
import { FetchModuleSlack } from '../actions/fetch-module-slack.action';
import {
    CopyELearningModuleToNewFailure,
    CopyELearningModuleToNewSuccess,
    CopyModuleToExisting,
    CopyModuleToExistingSuccess,
    CopyModuleToNew,
    CreateELearningModule,
    CreateELearningModuleFailure,
    CreateELearningModuleSuccess,
    UpdateELearningModule,
} from '../actions/index';
import { SetModuleKeyValue } from '../actions/set-module-key-value.action';
import { UpdateModuleContent } from '../actions/update-module-content.action';
import { UpdateModuleResponsibleManager } from '../actions/update-module-responsible-manager.action';
import { UpdateModuleStatus } from '../actions/update-module-status.action';
import { UpdateTemplateResponsibleManager } from '../actions/update-template-responsible-manager.action';
import { ModuleType } from '../enums/moduleType.enum';
import { ISetModuleKeyValueActionPayload } from '../interfaces/set-module-property.action-payload.interface';
import { ELearningModule } from './eLearning-module.model';
import { Module } from './module.model';
import { ModuleStateModel } from './module.state-model';
import { newModule } from './newModule';

@State<ModuleStateModel>({
    name: 'module',
    defaults: new ModuleStateModel(),
    children: [ModuleContentNodesState, ModuleParticipantsState],
})
@Injectable()
export class ModuleState extends EntityState<Module> implements IEntityState<Module> {
    @Selector()
    static getEntity(state: ModuleStateModel, id: string = state.item): Module {
        if (!state.map) {
            return null;
        }

        return state.map.get(id) || null;
    }

    @Selector()
    static getEntities(state: ModuleStateModel): Module[] {
        if (!state.map) {
            return null;
        }

        return Array.from(state.map.values());
    }

    @Selector()
    static getEntitiesAsMap(state: ModuleStateModel): Map<string, Module> {
        if (!state.map) {
            return null;
        }
        return state.map;
    }

    @Selector()
    static getMetrics(state: ModuleStateModel): FinalGradeMetrics {
        const item = ModuleState.getEntity(state);

        return item.metrics;
    }

    @Selector()
    static getParticipantScormRegistrationMetrics(state: ModuleStateModel): ParticipantRegistrationMetric {
        if (!state.map) {
            return null;
        }

        return state.participantScormRegistrationMetrics;
    }

    constructor(private _moduleApiService: ModuleApiService, private store: Store, private _scormService: ScormService) {
        super(_moduleApiService, 30);

        this._propstoSearch = Module.props_to_search;
    }

    @Action(AddModules)
    addEntities(ctx: StateContext<ModuleStateModel>, { payload }: AddModules): void {
        const state = ctx.getState();

        this._addEntities(ctx, {
            payload: payload.map((tempModule) => {
                const stateItem = state.map ? state.map.get(tempModule.id) : null;
                const updatedItem: Module = Object.assign(
                    newModule(tempModule.type),
                    tempModule,
                    stateItem
                        ? {
                              slackChannel: stateItem.slackChannel,
                              contentNodeId: stateItem.contentNodeId,
                              userContentNodeId: stateItem.userContentNodeId,
                              sharedContentNodeId: stateItem.sharedContentNodeId,
                              gradingSchemeId: stateItem.gradingSchemeId,
                              metrics: stateItem.metrics,
                          }
                        : {}
                );

                return updatedItem;
            }),
        });
    }

    @Action(SetModule)
    setEntity(ctx: StateContext<ModuleStateModel>, action: SetModule): void {
        this._setEntity(ctx, action);

        const state = ctx.getState();
        const stateItem = state.map ? state.map.get(action.payload.id) : null;
        const updatedItem: Module = Object.assign(
            newModule(action.payload.type),
            action.payload,
            stateItem
                ? {
                      contentNodeId: stateItem.contentNodeId,
                      userContentNodeId: stateItem.userContentNodeId,
                      sharedContentNodeId: stateItem.sharedContentNodeId,
                      gradingSchemeId: stateItem.gradingSchemeId,
                      metrics: stateItem.metrics,
                  }
                : {}
        );

        this._addEntities(ctx, { payload: [updatedItem] });
    }

    @Action(UnsetModule)
    unsetEntity(ctx: StateContext<ModuleStateModel>): void {
        this._unsetEntity(ctx);
    }

    @Action(CreateModule)
    createEntity(ctx: StateContext<ModuleStateModel>, action: CreateModule): void {
        this._createEntity(ctx, action, CreateModuleSuccess, CreateModuleFailure, AddModules).then(() => {});
    }

    @Action(UpdateModule)
    updateEntity(ctx: StateContext<ModuleStateModel>, action: UpdateModule): void {
        // update section before the module
        this._updateEntity(ctx, action, UpdateModuleSuccess, UpdateModuleFailure).then((entity) => {
            const state = ctx.getState();
            const stateItem = state.map ? state.map.get(entity.id) : null;
            const updatedItem: Module = Object.assign(
                newModule(entity.type),
                entity,
                stateItem
                    ? {
                          contentNodeId: stateItem.contentNodeId,
                          userContentNodeId: stateItem.userContentNodeId,
                          sharedContentNodeId: stateItem.sharedContentNodeId,
                          gradingSchemeId: stateItem.gradingSchemeId,
                          metrics: stateItem.metrics,
                      }
                    : {}
            );

            this._addEntities(ctx, { payload: [updatedItem] });
        });
    }

    @Action(UpdateModuleSection)
    updateModuleSection(ctx: StateContext<ModuleStateModel>, action: UpdateModule): void {
        this._moduleApiService.patchSectionModule(action.payload.id, action.payload.hasSections).subscribe(
            (entity) => {
                const state = ctx.getState();
                const stateItem = state.map ? state.map.get(entity.id) : null;
                const updatedItem: Module = Object.assign(
                    newModule(entity.type),
                    entity,
                    stateItem
                        ? {
                              contentNodeId: stateItem.contentNodeId,
                              userContentNodeId: stateItem.userContentNodeId,
                              sharedContentNodeId: stateItem.sharedContentNodeId,
                              gradingSchemeId: stateItem.gradingSchemeId,
                              metrics: stateItem.metrics,
                          }
                        : {}
                );

                this._addEntities(ctx, { payload: [updatedItem] });

                this.store.dispatch(new UpdateModuleSectionSuccess());
            },
            () => {
                this.store.dispatch(new UpdateModuleSectionFailure());
            }
        );
    }

    @Action(UpdateModuleStatus)
    updateModuleStatus(ctx: StateContext<ModuleStateModel>, { payload }: UpdateModuleStatus): void {
        this._moduleApiService.updateStatus(payload.module, payload.notify).subscribe(
            (entity) => {
                ctx.dispatch([new AddModules([entity]), new UpdateModuleSuccess(payload.module)]);
            },
            (response: HttpErrorResponse) => {
                ctx.dispatch(new UpdateModuleFailure({ response, entityId: payload.module.id }));
            }
        );
    }

    @Action(FetchMinimumAttendance)
    getMinimumAttendance({ dispatch }: StateContext<ModuleStateModel>, action: FetchMinimumAttendance): void {
        this._moduleApiService.getMinimumAttendance(action.payload).subscribe(
            (entity) => {
                dispatch(new FetchMinimumAttendanceSuccess(entity));
            },
            () => {
                dispatch(new FetchMinimumAttendanceFailure());
            }
        );
    }

    @Action(FetchTenantAdminModuleStats)
    getTenantAdminModuleStatsSuccess({ dispatch }: StateContext<ModuleStateModel>, action: FetchTenantAdminModuleStats): void {
        this._moduleApiService.getTenantAdminModuleStats(action.payload).subscribe(
            (entity) => {
                dispatch(new FetchTenantAdminModuleStatsSuccess(entity));
            },
            () => {
                dispatch(new FetchTenantAdminModuleStatsFailure());
            }
        );
    }

    @Action(UpdateMinimumAttendance)
    updateMinimumAttendance(ctx: StateContext<ModuleStateModel>, { payload }: UpdateMinimumAttendance): void {
        this._moduleApiService.updateMinimumAttendance(payload.id, payload.minimumAttendance).subscribe(
            (entity) => {
                ctx.dispatch(new UpdateMinimumAttendanceSuccess(payload));
            },
            (response: HttpErrorResponse) => {
                ctx.dispatch(new UpdateMinimumAttendanceFailure());
            }
        );
    }

    @Action(UpdateCompletionRule)
    updateModuleCompletionRule(ctx: StateContext<ModuleStateModel>, { payload }: UpdateCompletionRule): void {
        this._moduleApiService.updateCompletionRule(payload.module, payload.requiredSectionForCompletion).subscribe(
            (entity) => {
                ctx.dispatch([new AddModules([entity]), new UpdateCompletionRuleSuccess(payload.module)]);
            },
            (response: HttpErrorResponse) => {
                ctx.dispatch(new UpdateCompletionRuleFailure({ response, entityId: payload.module.id }));
            }
        );
    }

    @Action(UpdateModuleContent)
    updateModuleContent(ctx: StateContext<ModuleStateModel>, { payload }: UpdateModuleContent): void {
        this._moduleApiService.updateContent(payload).subscribe(
            (entity) => {
                const state = ctx.getState();
                const stateItem = state.map ? state.map.get(entity.id) : null;
                const updatedItem: Module = Object.assign(
                    newModule(entity.type),
                    entity,
                    stateItem
                        ? {
                              slackChannel: stateItem.slackChannel,
                              sharedContentNodeId: stateItem.sharedContentNodeId,
                              gradingSchemeId: stateItem.gradingSchemeId,
                              metrics: stateItem.metrics,
                          }
                        : {}
                );
                this._addEntities(ctx, {
                    payload: [updatedItem],
                });
                ctx.dispatch(new UpdateModuleSuccess(payload));
            },
            (response: HttpErrorResponse) => {
                ctx.dispatch(new UpdateModuleFailure({ response, entityId: payload.id }));
            }
        );
    }

    @Action(DeleteModule)
    deleteEntity(ctx: StateContext<ModuleStateModel>, action: DeleteModule): void {
        this._deleteEntity(ctx, action, DeleteModuleSuccess, DeleteModuleFailure).then(() => {});
    }

    @Action(FetchModule)
    fetchEntity(ctx: StateContext<ModuleStateModel>, action: FetchModule): void {
        this._fetchEntity(ctx, action, FetchModuleSuccess, FetchModuleFailure, AddModules, SetModule).then(() => {});
    }

    @Action(FetchModuleSuccess)
    fetchEntitySuccess(ctx: StateContext<ModuleStateModel>, action: FetchModuleSuccess): void {
        this._fetchEntitySuccess(ctx, action);
    }

    @Action(FetchModuleFailure)
    fetchEntityFailure(ctx: StateContext<ModuleStateModel>, action: FetchModuleFailure): void {
        this._fetchEntityFailure(ctx, action);
    }

    @Action(SetModules)
    setEntities(ctx: StateContext<ModuleStateModel>, action: SetModules): void {
        this._setEntities(ctx, action);
    }

    @Action(FetchModules)
    fetchEntities(ctx: StateContext<ModuleStateModel>): void {
        this._fetchEntities(ctx, FetchModulesSuccess, FetchModulesFailure, SetModules).then(() => {});
    }

    @Action(FetchModulesSuccess)
    fetchEntitiesSuccess(ctx: StateContext<ModuleStateModel>): void {
        this._fetchEntitiesSuccess(ctx);
    }

    @Action(FetchModulesFailure)
    fetchEntitiesFailure(ctx: StateContext<ModuleStateModel>): void {
        this._fetchEntitiesFailure(ctx);
    }

    private _setModuleUsers({ dispatch, getState }: StateContext<ModuleStateModel>, moduleId: string): void {
        const state = getState();

        if (state.item && state.item === moduleId) {
            const moduleUsers = this.store.selectSnapshot(ModuleUserState.getEntities);

            dispatch(
                new SetModuleParticipants(
                    moduleUsers
                        .filter((moduleUserTemp) => {
                            return moduleUserTemp.moduleId === moduleId && moduleUserTemp.isParticipant;
                        })
                        .map((moduleUserTemp) =>
                            Object.assign(new ModuleUserExtended(), moduleUserTemp, {
                                user: null,
                                module: null,
                            })
                        )
                )
            );

            dispatch(
                new SetModuleManagers(
                    moduleUsers
                        .filter((moduleUserTemp) => {
                            return moduleUserTemp.moduleId === moduleId && moduleUserTemp.isManager;
                        })
                        .map((moduleUserTemp) =>
                            Object.assign(new ModuleUserExtended(), moduleUserTemp, {
                                user: null,
                                module: null,
                            })
                        )
                )
            );
        }
    }

    @Action(UpdateModuleUserSuccess)
    updateModuleUserSuccess(ctx: StateContext<ModuleStateModel>, { payload }: UpdateModuleUserSuccess): void {
        payload.forEach((moduleUser) => {
            this._setModuleUsers(ctx, moduleUser.moduleId);
        });
    }

    private _setModulePropertyValue(ctx: StateContext<ModuleStateModel>, { moduleId, key, value }: ISetModuleKeyValueActionPayload): void {
        const state = ctx.getState();

        if (state.map && state.map.has(moduleId)) {
            const itemFromState = state.map.get(moduleId);
            const updatedItem = Object.assign(newModule(itemFromState.type), itemFromState, { [key]: value });
            this._addEntities(ctx, { payload: [updatedItem] });
        }
    }

    private getModuleSettableProperties(section: Module, removeNullValues: boolean = false): Partial<Module> {
        const toReturn = {};

        for (const propName in section) {
            if (propName !== 'type' && propName !== '_type' && propName !== 'constructor') {
                if (!removeNullValues) {
                    toReturn[propName] = section[propName];
                } else if (section[propName] !== null) {
                    toReturn[propName] = section[propName];
                }
            }
        }

        return toReturn;
    }

    @Action(SetModuleKeyValue)
    setModuleKeyValue(ctx: StateContext<ModuleStateModel>, { payload }: SetModuleKeyValue): void {
        this._setModulePropertyValue(ctx, payload);
    }

    @Action(FetchModuleContentNodeSuccess)
    fetchModuleContentNodeSuccess({ dispatch }: StateContext<ModuleStateModel>, { payload }: FetchModuleContentNodeSuccess): void {
        const moduleContentNodeBreadcrumb = payload.breadcrumbs[2];

        if (moduleContentNodeBreadcrumb) {
            dispatch(
                new SetModuleKeyValue({
                    moduleId: payload.rootId,
                    key: 'contentNodeId',
                    value: moduleContentNodeBreadcrumb.id,
                })
            );
        }
    }

    @Action(FetchPrivateContentNodeSuccess)
    fetchPrivateContentNodeSuccess({ dispatch }: StateContext<ModuleStateModel>, { payload }: FetchPrivateContentNodeSuccess): void {
        const moduleUserContentNodeBreadcrumb = payload.breadcrumbs[3];

        if (moduleUserContentNodeBreadcrumb) {
            dispatch(
                new SetModuleKeyValue({
                    moduleId: moduleUserContentNodeBreadcrumb.name, // the name is equal to the module ID it is linked to (this is deep linked to alfrescos structure)
                    key: 'userContentNodeId',
                    value: moduleUserContentNodeBreadcrumb.id,
                })
            );
        }
    }

    @Action(NotifyAllParticipants)
    notifyAllParticipants({ dispatch, getState }: StateContext<ModuleStateModel>, { payload }: NotifyAllParticipants): void {
        const state = getState();
        this._moduleApiService.notifyAll(payload, state.item).subscribe(
            () => {
                dispatch(new NotifyAllParticipantsSuccess());
            },
            (response: HttpErrorResponse) => {
                dispatch(new NotifyAllParticipantsFailure({ response }));
            }
        );
    }

    @Action(NotifySelectedParticipants)
    notifySelectedParticipants({ dispatch, getState }: StateContext<ModuleStateModel>, { payload }: NotifySelectedParticipants): void {
        const state = getState();
        this._moduleApiService.notifySelected(payload, state.item).subscribe(
            () => {
                dispatch(new NotifySelectedParticipantsSuccess());
            },
            (response: HttpErrorResponse) => {
                dispatch(new NotifySelectedParticipantsFailure({ response }));
            }
        );
    }

    private _updateResponsibleManager(moduleItem: Module, moduleUser: ModuleUser): Module {
        if (!moduleUser.isResponsible) {
            return Object.assign(newModule(moduleItem.type), moduleItem, {
                responsibleManagers: moduleItem.responsibleManagers.filter((responsibleManager) => responsibleManager.id !== moduleUser.userId),
            });
        }

        const user = UserState.getEntity(this.store.selectSnapshot(UserState), moduleUser.userId);

        if (!user) {
            console.error(`Unable to add user (id: ${moduleUser.userId}) as responsible manager to module (id: ${moduleItem.id}). The user could not be found. `);
            return moduleItem;
        }

        const filteredResponsibleManagers = moduleItem.responsibleManagers.filter((responsibleManager) => responsibleManager.id === moduleUser.userId);

        if (!filteredResponsibleManagers.length) {
            return Object.assign(newModule(moduleItem.type), moduleItem, {
                responsibleManagers: [...moduleItem.responsibleManagers, user],
            });
        }

        return moduleItem;
    }

    @Action(UpdateModuleResponsibleManager)
    updateModuleResponsibleManager(ctx: StateContext<ModuleStateModel>, { payload }: UpdateModuleResponsibleManager): void {
        const state = ctx.getState();

        payload.forEach((moduleUser) => {
            if (state.map && state.map.has(moduleUser.moduleId)) {
                const itemFromState = state.map.get(moduleUser.moduleId);
                const updatedItem = this._updateResponsibleManager(itemFromState, moduleUser);
                this._addEntities(ctx, { payload: [updatedItem] });
            }
        });
    }

    @Action(UpdateTemplateResponsibleManager)
    updateTemplateResponsibleManager(ctx: StateContext<ModuleStateModel>, { payload }: UpdateModuleResponsibleManager): void {
        const state = ctx.getState();

        payload.forEach((moduleUser) => {
            if (state.map && state.map.has(moduleUser.moduleId)) {
                const itemFromState = state.map.get(moduleUser.moduleId);
                const updatedItem = this._updateResponsibleManager(itemFromState, moduleUser);
                this._addEntities(ctx, { payload: [updatedItem] });
            }
        });
    }

    @Action(ImportModule)
    importModule(ctx: StateContext<ModuleStateModel>, { file }: ImportModule): void {
        this._moduleApiService.import(file).subscribe(
            (payload) => {
                ctx.dispatch(new ImportModuleSuccess(payload));
            },
            (response: HttpErrorResponse) => {
                ctx.dispatch(new ImportModuleFailure());
            }
        );
    }

    @Action(FetchModuleSlack)
    fetchModuleSlack(ctx: StateContext<ModuleStateModel>, { payload }: FetchModuleSlack): void {
        this._moduleApiService.getSlack(payload.moduleId).subscribe(
            (query) => {
                ctx.dispatch([
                    new SetModuleKeyValue({
                        moduleId: payload.moduleId,
                        key: 'slackChannel',
                        value: query.channel,
                    }),
                    new FetchModuleSlackSuccess(query),
                ]);
            },
            (response: HttpErrorResponse) => {
                ctx.dispatch(new FetchModuleSlackFailure());
            }
        );
    }

    @Action(FetchModuleFinalGradeMetrics)
    fetchModuleFinalGradeMetrics(ctx: StateContext<ModuleStateModel>, { payload }: FetchModuleFinalGradeMetrics): void {
        this._moduleApiService.metrics(payload).subscribe(
            (metrics) => {
                ctx.dispatch([
                    new SetModuleKeyValue({
                        moduleId: payload,
                        key: 'metrics',
                        value: metrics,
                    }),
                    new FetchModuleFinalGradeMetricsSuccess(metrics),
                ]);
            },
            (error: HttpErrorResponse) => {
                ctx.dispatch(
                    new FetchModuleFinalGradeMetricsFailure({
                        entityId: payload,
                        response: error,
                    })
                );
            }
        );
    }

    @Action(CopyModuleToExisting)
    copyModuleToExisting(ctx: StateContext<ModuleStateModel>, { payload }: CopyModuleToExisting): void {
        this._moduleApiService.copyModuleToExisting(payload.sourceModuleId, payload.targetModuleId).subscribe(
            (entity) => {
                ctx.dispatch(new CopyModuleToExistingSuccess({ id: entity.id, asyncJobId: entity.async_job_id }));
            },
            (error: HttpErrorResponse) => {
                ctx.dispatch(new CopyModuleToExistingFailure());
            }
        );
    }

    @Action(ConvertModuleToExecution)
    convertModuleToExecution(ctx: StateContext<ModuleStateModel>, { payload }: ConvertModuleToExecution): void {
        this._moduleApiService.convertModuleToExecution(payload.sourceModuleId, payload.courseOfferingId).subscribe(
            (entity) => {
                const state = ctx.getState();
                const stateItem = state.map ? state.map.get(entity.id) : null;

                const updatedItem: Module = Object.assign(
                    newModule(entity.type),
                    entity,
                    stateItem
                        ? {
                              kind: entity.kind,
                          }
                        : {}
                );

                this._addEntities(ctx, { payload: [updatedItem] });

                ctx.dispatch(new ConvertModuleToExecutionSuccess(entity));
            },
            (error: HttpErrorResponse) => {
                ctx.dispatch(new ConvertModuleToExecutionFailure());
            }
        );
    }

    @Action(ConvertExecutionToModule)
    convertExecutionToModule(ctx: StateContext<ModuleStateModel>, { payload }: ConvertModuleToExecution): void {
        this._moduleApiService.convertExecutionToModule(payload.sourceModuleId).subscribe(
            (entity) => {
                const state = ctx.getState();
                const stateItem = state.map ? state.map.get(entity.id) : null;

                const updatedItem: Module = Object.assign(
                    newModule(entity.type),
                    entity,
                    stateItem
                        ? {
                              kind: entity.kind,
                          }
                        : {}
                );

                this._addEntities(ctx, { payload: [updatedItem] });

                ctx.dispatch(new ConvertExecutionToModuleSuccess(entity));
            },
            (error: HttpErrorResponse) => {
                ctx.dispatch(new ConvertExecutionToModuleFailure());
            }
        );
    }

    @Action(CopyModuleToNew)
    copyModuleToNew(ctx: StateContext<ModuleStateModel>, { payload }: CopyModuleToNew): void {
        const module = Object.assign(newModule(ModuleType.Basic), payload);

        this._moduleApiService.copyModuleToNew(module).subscribe(
            (entity) => {
                ctx.dispatch(new CopyModuleToNewSuccess({ id: entity.id, asyncJobId: entity.async_job_id }));
            },
            (error: HttpErrorResponse) => {
                ctx.dispatch(new CopyModuleToNewFailure());
            }
        );
    }

    @Action(CopyELearningModuleToNew)
    copyELearningModuleToNew(ctx: StateContext<ModuleStateModel>, { payload }: CopyELearningModuleToNew): void {
        const module = Object.assign(newModule(ModuleType.ELearning), payload);

        this._moduleApiService.copyELearningModuleToNew(module).subscribe(
            (entity) => {
                ctx.dispatch(new CopyELearningModuleToNewSuccess({ id: entity.id, asyncJobId: entity.async_job_id }));
            },
            (error: HttpErrorResponse) => {
                ctx.dispatch(new CopyELearningModuleToNewFailure());
            }
        );
    }

    @Action(CreateELearningModule)
    createELearningModule(ctx: StateContext<ModuleStateModel>, { payload }: CreateELearningModule): void {
        this._moduleApiService.createELearningModule(payload).subscribe(
            (entity) => {
                ctx.dispatch(new CreateELearningModuleSuccess(entity as ELearningModule));
            },
            (response: HttpErrorResponse) => {
                ctx.dispatch(new CreateELearningModuleFailure({ response }));
            }
        );
    }

    @Action(GetModuleLaunchCourseUrl)
    getLaunchCourseUrl({ dispatch }: StateContext<ModuleStateModel>, action: GetModuleLaunchCourseUrl): void {
        this._moduleApiService.getModuleLaunchCourseUrl(action.payload).subscribe(
            (entity) => {
                dispatch(new GetModuleLaunchCourseUrlSuccess(entity.launchLink));
            },
            () => {
                dispatch(new GetModuleLaunchCourseUrlFailure());
            }
        );
    }

    @Action(GetCourseStructureModuleBreadcrumbs)
    getCourseStructureModuleBreadcrumbs({ dispatch }: StateContext<ModuleStateModel>, action: GetCourseStructureModuleBreadcrumbs): void {
        this._moduleApiService.getCourseStructureModuleBreadcrumbs(action.payload).subscribe(
            (entity) => {
                dispatch(new GetCourseStructureModuleBreadcrumbsSuccess(entity));
            },
            () => {
                dispatch(new GetCourseStructureModuleBreadcrumbsFailure());
            }
        );
    }

    @Action(GetModuleParticipantScormRegistrationMetrics)
    getParticipantScormRegistrationMetrics({ dispatch, patchState }: StateContext<ModuleStateModel>, action: GetModuleParticipantScormRegistrationMetrics): void {
        this._moduleApiService.getModuleParticipantRegistrationData(action.payload).subscribe(
            (entity) => {
                patchState({
                    participantScormRegistrationMetrics: entity,
                });

                dispatch(new GetModuleParticipantScormRegistrationMetricsSuccess(entity));
            },
            () => {
                dispatch(new GetModuleParticipantScormRegistrationMetricsFailure());
            }
        );
    }

    @Action(UnsetModuleParticipantScormRegistrationMetrics)
    unsetParticipantScormRegistrationMetrics({ getState, patchState }: StateContext<ModuleStateModel>): void {
        patchState({
            participantScormRegistrationMetrics: null,
        });
    }

    @Action(UpdateELearningModule)
    updateELearningModule(ctx: StateContext<ModuleStateModel>, action: UpdateELearningModule): void {
        this._updateEntity(ctx, action, UpdateELearningModuleSuccess, UpdateELearningModuleFailure, null, this._moduleApiService.updateELearningModule(action.payload)).then(
            (entity) => {
                const state = ctx.getState();
                const stateItem = state.map ? state.map.get(entity.id) : null;
                const updatedItem: Module = Object.assign(
                    newModule(entity.type),
                    entity,
                    stateItem
                        ? {
                              contentNodeId: stateItem.contentNodeId,
                              userContentNodeId: stateItem.userContentNodeId,
                              sharedContentNodeId: stateItem.sharedContentNodeId,
                              gradingSchemeId: stateItem.gradingSchemeId,
                              metrics: stateItem.metrics,
                          }
                        : {}
                );

                this._addEntities(ctx, { payload: [updatedItem] });
            }
        );
    }

    @Action(FetchModuleImportStatus)
    fetchImportStatus(ctx: StateContext<ModuleStateModel>, { payload }: FetchModuleImportStatus): void {
        this._scormService.getImportStatus(payload.jobId).subscribe(
            (entity) => {
                const state = ctx.getState();

                if (state.map && state.map.has(payload.entityId)) {
                    const itemFromState = state.map.get(payload.entityId);
                    const updatedItem = Object.assign<ELearningModule, Partial<ELearningModule>, Partial<ELearningModule>>(
                        new ELearningModule(),
                        this.getModuleSettableProperties(itemFromState),
                        {
                            ['courseImportJobId']: payload.jobId,
                        }
                    );

                    this._addEntities(ctx, { payload: [updatedItem] });
                }

                switch (entity.status) {
                    case 'ERROR':
                        ctx.dispatch(new FetchModuleImportStatusFailure());
                        break;
                    case 'COMPLETE':
                        ctx.dispatch(new FetchModuleImportStatusSuccess(entity));
                        break;
                    default:
                        break;
                }
            },
            () => {
                ctx.dispatch(new FetchModuleImportStatusFailure());
            }
        );
    }

    @Action(FetchModuleCourseStatus)
    fetchCourseStatus(ctx: StateContext<ModuleStateModel>, { payload }: FetchModuleCourseStatus): void {
        this._scormService.getCourseById(payload.courseId).subscribe(
            () => {
                ctx.dispatch(new FetchModuleCourseStatusSuccess());
            },
            () => {
                ctx.dispatch(new FetchModuleCourseStatusFailure());
            }
        );
    }

    @Action(PatchModuleCourseId)
    patchModuleCourseId(ctx: StateContext<ModuleStateModel>, { payload }: PatchModuleCourseId): void {
        const state = ctx.getState();
        const itemFromState = state.map.get(payload.entityId);
        const updatedItem = Object.assign<ELearningModule, Partial<ELearningModule>, Partial<ELearningModule>>(
            new ELearningModule(),
            this.getModuleSettableProperties(itemFromState),
            {
                ['courseId']: payload.courseId,
            }
        );

        this._addEntities(ctx, { payload: [updatedItem] });
    }

    @Action(ModuleSection)
    patchModuleSection(ctx: StateContext<ModuleStateModel>, { payload }: ModuleSection): void {
        this._moduleApiService.patchSectionModule(payload.id, payload.isEnable);
    }

    @Action(AddScheduleToModule)
    addScheduleToSection(ctx: StateContext<ModuleStateModel>, { payload }: AddScheduleToModule): void {
        const state = ctx.getState();
        let itemFromState = state.map.get(payload.entity.id);

        itemFromState.schedules.push(payload.scheduledJob);

        const schedules = itemFromState.schedules.map((schedule) => schedule);

        const updatedItem = Object.assign<Module, Partial<Module>, Partial<Module>>(newModule(payload.entity.type), itemFromState, {
            schedules,
        });

        this._setEntities(ctx, { payload: [...Array.from(state.map.values()), updatedItem] });
    }

    @Action(UpdateScheduleInModule)
    updateSchedulelInSection(ctx: StateContext<ModuleStateModel>, { payload }: UpdateScheduleInModule): void {
        const state = ctx.getState();
        let itemFromState = state.map.get(payload.entity.id);

        const schedules = itemFromState.schedules.map((schedule) => {
            if (schedule.id !== payload.scheduledJob.id) {
                schedule = payload.scheduledJob;
            }
            return schedule;
        });
        const updatedItem = Object.assign<Module, Partial<Module>, Partial<Module>>(newModule(payload.entity.type), itemFromState, {
            schedules,
        });

        this._addEntities(ctx, { payload: [updatedItem] });
    }

    @Action(RemoveScheduleFromModule)
    removeScheduleFromSection(ctx: StateContext<ModuleStateModel>, { payload }: RemoveScheduleFromModule): void {
        const state = ctx.getState();
        let itemFromState = state.map.get(payload.entity.id);

        const schedules = itemFromState.schedules.filter((schedule) => schedule.id !== payload.scheduledJobId);
        const updatedItem = Object.assign<Module, Partial<Module>, Partial<Module>>(newModule(payload.entity.type), itemFromState, {
            schedules,
        });

        this._addEntities(ctx, { payload: [updatedItem] });
    }

    @Action(SaveNotificationDefaultText)
    saveNotificationDefaultText(ctx: StateContext<ModuleStateModel>, { payload }: SaveNotificationDefaultText): void {
        this._moduleApiService.saveDefaultText(payload).subscribe(
            (entity) => {
                ctx.dispatch(new SaveNotificationDefaultTextSuccess(entity.default_text));
            },
            () => {
                ctx.dispatch(new SaveNotificationDefaultTextFailure());
            }
        );
    }
}
