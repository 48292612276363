import { IEntity } from 'app/projects/entity/src/lib/interfaces/entity.interface';
import * as momentImported from 'moment';
import { ModuleKind } from '../enums/module-kind.enum';
import { SectionRule } from '../enums/sectionRule.enum';
import { IModuleDuration } from '../interfaces/module-duration.interface';
import { Module } from './module.model';
const moment = momentImported;

export class ModuleCommand {
    private: boolean;
    name: string;
    description: string;
    start_date: string;
    end_date: string;
    due_date: string;
    participant_count: number;
    responsible_managers: IEntity[];
    status: string;
    tags: IEntity[];
    rule: 'default' | 'sequential';
    section: boolean;
    section_rule: SectionRule;
    expected_duration: number;
    duration: IModuleDuration;
    default_pinned: boolean;
    self_paced: boolean;
    kind: ModuleKind;
    publish_sections: boolean;
    version: string;

    constructor(tempModule: Module) {
        // Removes name from action payload (can't be edited, added validation on backend)
        if (tempModule.kind !== ModuleKind.Template && tempModule.kind !== ModuleKind.ProgramTemplate) {
            this.name = tempModule.name;
        }
        this.private = tempModule.isPrivate;
        this.description = tempModule.description;
        this.start_date = tempModule.startDate ? moment(tempModule.startDate).format() : null;
        this.end_date = tempModule.endDate ? moment(tempModule.endDate).format() : null;
        this.due_date = tempModule.dueDate ? moment(tempModule.dueDate).format() : null;
        this.participant_count = tempModule.numberOfParticipants;
        this.responsible_managers = tempModule.responsibleManagers;
        this.status = tempModule.status;
        this.tags = tempModule.tags;
        this.section = tempModule.hasSections;
        this.section_rule = tempModule.sectionRule;
        this.expected_duration = parseInt(tempModule.duration, 10);
        this.duration = tempModule.digicompDuration;
        this.default_pinned = tempModule.defaultPinned;
        this.self_paced = tempModule.selfPaced;
        this.kind = tempModule.kind;
        this.publish_sections = tempModule.publishSections;
        this.version = tempModule.version;
    }
}
