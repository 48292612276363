import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IAudienceUserQuery } from 'app/library/audience-user/interfaces/audience-user.query.interface';
import { IEnvironment } from 'app/projects/core/src/lib/interfaces/environment.interface';
import { MAXBRAIN_ENVIRONMENT } from 'app/projects/core/src/lib/services/environment.token';
import { IPaginatedResponse } from 'app/projects/subentity/src/lib/interfaces/paginated-response.interface';
import { Observable } from 'rxjs';

@Injectable()
export class AudienceUsersApiService {
    constructor(private http: HttpClient, @Inject(MAXBRAIN_ENVIRONMENT) private environment: IEnvironment) {}

    getAudienceUsers(params?: HttpParams): Observable<IPaginatedResponse<IAudienceUserQuery>> {
        return this.http.get<IPaginatedResponse<IAudienceUserQuery>>(`${this.environment.apiUrl}/engage/audiences-users`, { params });
    }
}
