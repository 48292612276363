import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { AddAudienceUsers } from 'app/library/audience-user/actions/add-entities.action';
import { AudienceUserExtended } from 'app/library/audience-user/models/audience-user.extended.model';
import { AudienceUser } from 'app/library/audience-user/models/audience-user.model';
import { AudienceUserState } from 'app/library/audience-user/models/audience-user.state';
import { FeatureService } from 'app/library/feature/services/feature.service';
import { UserService } from 'app/library/user/services/user.service';
import { FeatureSwitchName } from 'app/projects/shared/src/lib/enums/feature-switch.enum';
import { SubentityState } from 'app/projects/subentity/src/lib/models/subentity.state';
import { UtilsState } from 'app/projects/subentity/src/lib/models/utils.state';
import { RefreshUsersOnlineState } from 'app/projects/user/src/lib/actions/refresh-users-online-state.action';
import { User } from 'app/projects/user/src/lib/models/user';
import { UserState } from 'app/projects/user/src/lib/models/user.state';
import { map } from 'rxjs/operators';
import {
    DeselectSelectedAudienceUsers,
    FetchAudienceUsers,
    FetchAudienceUsersFailure,
    FetchAudienceUsersSuccess,
    SearchAudienceUsers,
    SelectFilteredAudienceUsers,
    SetAudienceUsers,
    SortAudienceUsers,
    ToggleSelectedAudienceUser,
    UnsetAudienceUsers,
} from '../actions';
import { AudienceUsersApiService } from '../services/audience-users.api-service';
import { AudienceUsersStateModel } from './audience-users.state-model';

@State<AudienceUsersStateModel>({
    name: 'audienceUsersCopy',
    defaults: new AudienceUsersStateModel(),
})
@Injectable()
export class AudienceUsersState extends SubentityState<AudienceUser> {
    @Selector([AudienceUserState.getEntitiesAsMap])
    static getEntities(state: AudienceUsersStateModel, audienceUsers: Map<string, AudienceUser>): AudienceUser[] {
        if (!audienceUsers) {
            return null;
        }

        return UtilsState.getEntities(state, audienceUsers);
    }

    @Selector([AudienceUserState.getEntitiesAsMap, UserState.getEntities])
    static getEntitiesWithUser(state: AudienceUsersStateModel, audienceUsers: Map<string, AudienceUser>, users: User[]): AudienceUserExtended[] {
        const toReturn = AudienceUsersState.getEntities(state, audienceUsers);

        if (toReturn && users) {
            const usersContainer = {};

            users.forEach((user) => {
                usersContainer[user.id] = user;
            });

            return toReturn.map<AudienceUserExtended>((audienceUser) =>
                Object.assign<AudienceUserExtended, AudienceUser, Partial<AudienceUserExtended>>(new AudienceUserExtended(), audienceUser, {
                    user: usersContainer[audienceUser.userId] || null,
                })
            );
        }

        return null;
    }

    @Selector([AudienceUserState.getEntitiesAsMap])
    static getFilteredEntities(state: AudienceUsersStateModel, audienceUsers: Map<string, AudienceUser>): AudienceUser[] {
        if (!audienceUsers) {
            return null;
        }

        return UtilsState.getFilteredEntities(state, audienceUsers);
    }

    @Selector([AudienceUserState.getEntitiesAsMap, UserState.getEntities])
    static getFilteredEntitiesWithUser(state: AudienceUsersStateModel, audienceUsers: Map<string, AudienceUser>, users: User[]): AudienceUserExtended[] {
        const toReturn = AudienceUsersState.getFilteredEntities(state, audienceUsers);

        if (toReturn && users) {
            const usersContainer = {};

            users.forEach((user) => {
                usersContainer[user.id] = user;
            });

            return toReturn.map<AudienceUserExtended>((audienceUser) =>
                Object.assign<AudienceUserExtended, AudienceUser, Partial<AudienceUserExtended>>(new AudienceUserExtended(), audienceUser, {
                    user: usersContainer[audienceUser.userId] || null,
                })
            );
        }

        return null;
    }

    @Selector()
    static isFetchingList(state: AudienceUsersStateModel): boolean {
        return state.fetchingList;
    }

    @Selector()
    static getSelectedEntityIds(state: AudienceUsersStateModel): string[] {
        return state.selected;
    }

    constructor(private audienceUsersService: AudienceUsersApiService, private _userService: UserService, private _featureService: FeatureService, private store: Store) {
        super(null, 0);

        this._propstoSearch = ['user', ...User.props_to_search];
    }

    @Selector()
    static getSearchTerm(state: AudienceUsersStateModel): string {
        return state.searchTerm;
    }

    @Selector()
    static getTotalCount(state: AudienceUsersStateModel): number {
        return state.totalCount;
    }

    @Selector()
    static getSortBy(state: AudienceUsersStateModel): string {
        return state.sortBy;
    }

    @Selector()
    static getSortOrder(state: AudienceUsersStateModel): SortDirection {
        return state.sortOrder;
    }

    @Selector()
    static getPageSize(state: AudienceUsersStateModel): number {
        return state.pageSize;
    }

    @Selector()
    static getPageNumber(state: AudienceUsersStateModel): number {
        return state.pageNumber || 1;
    }

    @Selector()
    static getPageIndex(state: AudienceUsersStateModel): number {
        return state.pageNumber ? state.pageNumber - 1 : 0;
    }

    @Action(SetAudienceUsers)
    setAll(ctx: StateContext<AudienceUsersStateModel>, action: SetAudienceUsers): void {
        this._setSubentities(ctx, action, AddAudienceUsers);

        setTimeout(() => {
            const audienceUsersWithUser = this.store.selectSnapshot(AudienceUsersState.getEntitiesWithUser);

            if (audienceUsersWithUser && this._featureService.checkFeatureStatus(FeatureSwitchName.UserOnlineState)) {
                this.store.dispatch(new RefreshUsersOnlineState(audienceUsersWithUser.map((audienceUser) => audienceUser.user)));
                this._userService.setOnlineStateInterval(
                    RefreshUsersOnlineState,
                    audienceUsersWithUser.map((audienceUser) => audienceUser.user)
                );
            }
        });
    }

    @Action(UnsetAudienceUsers)
    unsetAll(ctx: StateContext<AudienceUsersStateModel>): void {
        ctx.setState(new AudienceUsersStateModel());
    }

    @Action(FetchAudienceUsers)
    fetchAll(ctx: StateContext<AudienceUsersStateModel>, { payload }: FetchAudienceUsers): void {
        let params = new HttpParams().set('page', payload.pageNumber.toString()).set('limit', payload.pageSize.toString());

        if (payload.search) {
            params = params.set('search', payload.search);
        }

        if (payload.sortBy) {
            params = params.set('sortBy', payload.sortBy).set('sortOrder', payload.sortOrder);
        }

        if (payload.audienceId) {
            params = params.set('audience', payload.audienceId);
        }
        this._fetchSubentities(
            ctx,
            { payload: payload.audienceId },
            SetAudienceUsers,
            FetchAudienceUsersSuccess,
            FetchAudienceUsersFailure,
            this.audienceUsersService.getAudienceUsers(params).pipe(
                map((data) => {
                    ctx.patchState({
                        totalCount: data.pagination.number_of_results,
                    });

                    return data.data.map((query) => new AudienceUserExtended(query));
                })
            )
        );
    }

    @Action(FetchAudienceUsersSuccess)
    fetchAllSuccess(ctx: StateContext<AudienceUsersStateModel>): void {
        this._fetchSubentitiesSuccess(ctx);
    }

    @Action(FetchAudienceUsersFailure)
    fetchAllFailure(ctx: StateContext<AudienceUsersStateModel>): void {
        this._fetchSubentitiesFailure(ctx);
    }

    @Action(ToggleSelectedAudienceUser)
    toggleSelect(ctx: StateContext<AudienceUsersStateModel>, action: ToggleSelectedAudienceUser): void {
        this._toggleSelectedSubentity(ctx, action);
    }

    @Action(SelectFilteredAudienceUsers)
    selectAll(ctx: StateContext<AudienceUsersStateModel>): void {
        this._selectFilteredSubentities(ctx);
    }

    @Action(DeselectSelectedAudienceUsers)
    deselectAll(ctx: StateContext<AudienceUsersStateModel>): void {
        this._deselectSelectedSubentities(ctx);
    }

    @Action(SearchAudienceUsers)
    search(ctx: StateContext<AudienceUsersStateModel>, action: SearchAudienceUsers): void {
        const users = this.store.selectSnapshot(UserState.getEntities);
        const audienceUsers = this.store.selectSnapshot(AudienceUserState.getEntities);

        const extendedAudienceUsers = audienceUsers.map((audienceUser) => ({
            ...audienceUser,
            user: users.filter((user) => user.id === audienceUser.userId)[0],
            audience: null,
        }));

        this._searchSubentities(ctx, action, extendedAudienceUsers);
    }

    @Action(SortAudienceUsers)
    sort({ patchState }: StateContext<AudienceUsersStateModel>, { payload }: SortAudienceUsers): void {
        sessionStorage.setItem('audienceUsersSortBy', payload.active);
        sessionStorage.setItem('audienceUsersSortOrder', payload.direction);

        patchState({
            sortBy: payload.active,
            sortOrder: payload.direction,
        });
    }
}
