import { HttpErrorResponse } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { AudienceUserExtended } from 'app/library/audience-user/models/audience-user.extended.model';
import { IManageAudienceUserActionPayload } from '../interfaces/audience-user-action.action-payload.interface';
import { AudienceUser } from '../models/audience-user.model';

export class SetAudienceUsers {
    static readonly type = '[AudienceUser] SetAudienceUsers';

    constructor(public payload: AudienceUserExtended[]) {}
}

export class UnsetAudienceUsers {
    static readonly type = '[AudienceUser] UnsetAudienceUsers';
}

export class FetchAudienceUsers {
    static readonly type = '[AudienceUser] FetchAudienceUsers';

    constructor(
        public payload?: {
            audienceId: string;
            search?: string;
            sortBy?: string;
            sortOrder?: string;
            pageSize?: number;
            pageNumber?: number;
        }
    ) {}
}

export class FetchAudienceUsersSuccess {
    static readonly type = '[AudienceUser] FetchAudienceUsersSuccess';
}

export class FetchAudienceUsersFailure {
    static readonly type = '[AudienceUser] FetchAudienceUsersFailure';
}

export class ToggleSelectedAudienceUser {
    static readonly type = '[AudienceUser] ToggleSelectedAudienceUser';

    constructor(public payload: string) {}
}

export class SelectFilteredAudienceUsers {
    static readonly type = '[AudienceUser] SelectFilteredAudienceUsers';
}

export class DeselectSelectedAudienceUsers {
    static readonly type = '[AudienceUser] DeselectSelectedAudienceUsers';
}

export class SearchAudienceUsers {
    static readonly type = '[AudienceUser] SearchAudienceUsers';

    constructor(public payload: string) {}
}

export class SortAudienceUsers {
    static readonly type = '[AudienceUser] SortAudienceUsers';

    constructor(public payload: Sort) {}
}

export class AddUsersToAudiences {
    static readonly type = '[AudienceUsers] AddUsersToAudience';

    constructor(public payload: IManageAudienceUserActionPayload) {}
}

export class AddUsersToAudiencesSuccess {
    static readonly type = '[AudienceUsers] AddUsersToAudienceSuccess';

    constructor(public payload: AudienceUser[]) {}
}

export class AddUsersToAudiencesFailure {
    static readonly type = '[AudienceUsers] AddUsersToAudienceFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}
