import { Injectable } from '@angular/core';
import { UserDetailsCardComponent } from './user-details-card.component';

@Injectable()
export class UserDetalisCardService {
    activeUserDetailsCard: UserDetailsCardComponent;

    toggle(userDetailsCard: UserDetailsCardComponent): void {
        if (!this.activeUserDetailsCard) {
            this.activeUserDetailsCard = userDetailsCard;
        } else if (this.activeUserDetailsCard !== userDetailsCard) {
            this.activeUserDetailsCard.hide = true;
            this.activeUserDetailsCard = userDetailsCard;
        }

        this.activeUserDetailsCard.hide = !this.activeUserDetailsCard.hide;
    }
}
