import { Injectable } from '@angular/core';
import { Actions, ofActionDispatched, Select, Store } from '@ngxs/store';
import {
    AddUsersToAudience,
    AddUsersToAudienceFailure,
    AddUsersToAudienceSuccess,
    AssignUserToAudience,
    AssignUserToAudienceFailure,
    AssignUserToAudienceSuccess,
    DeleteAudienceUsers,
    DeleteAudienceUsersFailure,
    DeleteAudienceUsersSuccess,
    FetchAudienceByUserId,
    FetchAudienceByUserIdFailure,
    FetchAudienceByUserIdSuccess,
    MoveAudienceUsers,
    MoveAudienceUsersFailure,
    MoveAudienceUsersSuccess,
} from 'app/library/audience-user/actions';
import { AudienceUserExtended } from 'app/library/audience-user/models/audience-user.extended.model';
import { AudienceUser } from 'app/library/audience-user/models/audience-user.model';
import { BehaviorSubject, Observable, race } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UnsetAudienceUsers } from '../actions';
import { IManageAudienceUserActionPayload } from '../interfaces/audience-user-action.action-payload.interface';
import { AudienceUsersState } from '../models/audience-users.state';

@Injectable()
export class AudienceUsersService {
    @Select(AudienceUsersState.getEntities)
    audienceUser$: Observable<AudienceUser[]>;

    private _audienceUsersSubject = new BehaviorSubject<AudienceUser[]>(null);
    public audienceUsersSubject$ = this._audienceUsersSubject.asObservable();

    constructor(private _store: Store, private _action$: Actions) {}

    getAudienceUsers(): AudienceUser[] {
        return this._store.selectSnapshot(AudienceUsersState.getEntities);
    }

    async fetchAudiencesByUserId(userId: string, pageSize?: number, pageIndex?: number): Promise<AudienceUserExtended[]> {
        this._store.dispatch(new FetchAudienceByUserId({ userId, pageSize, pageIndex }));

        return race(
            this._action$.pipe(
                ofActionDispatched(FetchAudienceByUserIdSuccess),
                map((action: FetchAudienceByUserIdSuccess) => {
                    this._audienceUsersSubject.next(action.payload);
                    return action.payload;
                })
            ),
            this._action$.pipe(
                ofActionDispatched(FetchAudienceByUserIdFailure),
                map(() => null)
            )
        )
            .pipe(take(1))
            .toPromise();
    }

    addUsersToAudience(
        payload: IManageAudienceUserActionPayload
    ): Promise<{ newlyAssigned: AudienceUserExtended[]; alreadyInChildren: AudienceUserExtended[]; alreadyAssigned: AudienceUserExtended[] }> {
        this._store.dispatch(new AddUsersToAudience(payload));

        return race(
            this._action$.pipe(
                ofActionDispatched(AddUsersToAudienceSuccess),
                map((action: AddUsersToAudienceSuccess) => action.payload)
            ),
            this._action$.pipe(
                ofActionDispatched(AddUsersToAudienceFailure),
                map(() => null)
            )
        )
            .pipe(take(1))
            .toPromise();
    }

    clearAudienceUsersState() {
        this._store.dispatch(new UnsetAudienceUsers());
    }

    assignUserToAudience(
        payload: IManageAudienceUserActionPayload
    ): Promise<{ newlyAssigned: AudienceUserExtended[]; alreadyInChildren: AudienceUserExtended[]; alreadyAssigned: AudienceUserExtended[] }> {
        this._store.dispatch(new AssignUserToAudience(payload));

        return race(
            this._action$.pipe(
                ofActionDispatched(AssignUserToAudienceSuccess),
                map((action: AssignUserToAudienceSuccess) => {
                    return action.payload;
                })
            ),
            this._action$.pipe(
                ofActionDispatched(AssignUserToAudienceFailure),
                map(() => null)
            )
        )
            .pipe(take(1))
            .toPromise();
    }

    moveAudienceUsers(
        source: string,
        target: string,
        selectedUserIds: [{ id: string }]
    ): Promise<{ newlyAssigned: AudienceUserExtended[]; alreadyInChildren: AudienceUserExtended[]; alreadyAssigned: AudienceUserExtended[] }> {
        this._store.dispatch(new MoveAudienceUsers({ source, target, selectedUserIds }));

        return race(
            this._action$.pipe(
                ofActionDispatched(MoveAudienceUsersSuccess),
                map((action: MoveAudienceUsersSuccess) => {
                    return action.payload;
                })
            ),
            this._action$.pipe(ofActionDispatched(MoveAudienceUsersFailure))
        )
            .pipe(take(1))
            .toPromise();
    }

    moveRootAudienceUsers(
        payload: IManageAudienceUserActionPayload
    ): Promise<{ newlyAssigned: AudienceUserExtended[]; alreadyInChildren: AudienceUserExtended[]; alreadyAssigned: AudienceUserExtended[] }> {
        this._store.dispatch(new AddUsersToAudience(payload));

        return race(
            this._action$.pipe(
                ofActionDispatched(AddUsersToAudienceSuccess),
                map((action: AddUsersToAudienceSuccess) => {
                    return action.payload;
                })
            ),
            this._action$.pipe(ofActionDispatched(AddUsersToAudienceFailure))
        )
            .pipe(take(1))
            .toPromise();
    }

    deleteAudienceUsers(audienceUsers: { audienceUsers: [{ id: string }] }): Promise<boolean> {
        this._store.dispatch(new DeleteAudienceUsers(audienceUsers));

        return race(
            this._action$.pipe(
                ofActionDispatched(DeleteAudienceUsersSuccess),
                map((action: DeleteAudienceUsersSuccess) => true)
            ),
            this._action$.pipe(
                ofActionDispatched(DeleteAudienceUsersFailure),
                map((action: DeleteAudienceUsersFailure) => false)
            )
        )
            .pipe(take(1))
            .toPromise();
    }

    /* getUserSectionStatById(id: string): UserSectionStats {
        return UserSectionStatsState.getEntity(this._store.selectSnapshot(UserSectionStatsState), id);
    } */
}
