export enum CompletionRule {
    UNDEFINED = 0,
    MANUALLY = 1,
    AUTO_COMPLETE_ALL_COURSES = 2,
    AUTO_RECEIVE_CERTIFICATE = 3,
    AUTO_ACHIEVE_PASSING_GRADE = 4,
    AUTO_END_DATE_REACHED = 5,
    AUTO_COMPLETE_ALL_COURSES_AND_MIN_ATTENDANCE_REACHED = 6,
    AUTO_END_DATE_REACHED_AND_MIN_ATTENDANCE_REACHED = 7,
    AUTO_ALL_SECTIONS = 8,
    AUTO_MANDATORY_SECTIONS = 9,
    AUTO_SPECIFIED_SECTIONS = 10,
    SECTIONS = 99,
}
