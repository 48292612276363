import { Module } from 'app/library/module/models/module.model';
import { CertificateRecord } from 'app/library/certificate-record/models/certificate-record';
import { Section } from 'app/library/section/models/section.model';
import { SectionType } from 'app/library/section/enums/section-type.enum';
import { ELearningSection } from 'app/library/section/models/eLearning-section.model';
import { FeedbackSection } from 'app/library/section/models/feedback-section.model';
import { FilesSection } from 'app/library/section/models/files-section.model';
import { QuizSection } from 'app/library/section/models/quiz-section.model';
import { SurveySection } from 'app/library/section/models/survey-section.model';
import { TextSection } from 'app/library/section/models/text-section.model';
import { ISectionQuery } from 'app/library/section/interfaces/section.query.interface';
import { User } from 'app/projects/user/src/lib/models/user';
import { IToDoQuery } from '../interfaces/to-do.query.interface';

export class ToDo {
    id: string;
    type: number;
    status: number;
    title: string;
    assignee: User;
    dueDate: Date;
    completedAt: Date;
    isViewedByAssignee: boolean;
    isOverdue: boolean;
    isExpired: boolean;
    moduleToDo: {
        id: string;
        module: Module;
    };
    certificateToDo: {
        id: string;
        certificate: CertificateRecord;
    };
    sectionToDo: {
        id: string;
        section: Section;
    };

    constructor(query: IToDoQuery) {
        this.id = query.id.toString();
        this.type = query.type;
        this.status = query.status;
        this.title = query.title;
        this.assignee = new User(query.assignee);
        this.dueDate = query.due_date ? new Date(query.due_date) : null;
        this.completedAt = query.completed_at ? new Date(query.completed_at) : null;
        this.isViewedByAssignee = query.is_viewed_by_assignee;
        this.isOverdue = query.is_overdue;
        this.isExpired = query.is_expired;
        this.moduleToDo = query.module_to_do ? { id: query.module_to_do.id.toString(), module: new Module(query.module_to_do.module) } : null;
        this.certificateToDo = query.certificate_to_do
            ? { id: query.certificate_to_do.id.toString(), certificate: new CertificateRecord(query.certificate_to_do.certificate) }
            : null;
        this.sectionToDo = query.section_to_do ? { id: query.section_to_do.id.toString(), section: this._newSection(query.section_to_do.section) } : null;
    }

    private _newSection(query: ISectionQuery): Section {
        switch (query.type) {
            case SectionType.Files:
                return new FilesSection(query);
            case SectionType.Text:
                return new TextSection(query);
            case SectionType.Feedback:
                return new FeedbackSection(query);
            case SectionType.Quiz:
                return new QuizSection(query);
            case SectionType.ELearning:
                return new ELearningSection(query);
            case SectionType.Survey:
                return new SurveySection(query);
        }
    }
}
