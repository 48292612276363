import { ModuleWithProviders, NgModule } from '@angular/core';
import { UserDetailsCardModule } from '../shared/user-details-card/user-details-card.module';
import { ModuleUserApiService } from './services/module-user.api-service';
import { ModuleUserService } from './services/module-user.service';

@NgModule({
    exports: [UserDetailsCardModule],
})
export class MaxBrainModuleUserModule {
    static forRoot(): ModuleWithProviders<MaxBrainModuleUserModule> {
        return {
            ngModule: MaxBrainModuleUserModule,
            providers: [ModuleUserApiService, ModuleUserService],
        };
    }
}
