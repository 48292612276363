import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MaxBrainAudience } from 'app/library/audience/models/audience.model';
import { AudienceService } from 'app/library/audience/services/audience.service';

@Component({
    selector: 'app-add-default-audience-dialog',
    templateUrl: './add-default-audience-dialog.component.html',
    styleUrls: ['./add-default-audience-dialog.component.scss'],
})
export class AddDefaultAudienceDialogComponent implements OnInit {
    @Input()
    audienceListTree: any; //TODO: [0] build failed with AudienceResource[];

    @Input()
    isRootDisabled: boolean = false;

    @Output()
    save = new EventEmitter<MaxBrainAudience>();

    private _formGroup: FormGroup;

    get formGroup(): FormGroup {
        return this._formGroup;
    }

    constructor(private _formBuilder: FormBuilder, public dialogRef: MatDialogRef<AddDefaultAudienceDialogComponent>, public audienceService: AudienceService) {}

    ngOnInit() {
        this._formGroup = this._formBuilder.group({
            audience: ['', Validators.required],
        });
    }

    async onAddNewAudience() {
        const audience = this.formGroup.get('audience').value;
        this.save.emit(audience);
    }
}
