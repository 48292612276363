import { HttpErrorResponse } from '@angular/common/http';
import { IManageAudienceUserActionPayload } from 'app/library/audience-users/interfaces/audience-user-action.action-payload.interface';
import { IManageAudienceUsersActionPayload } from 'app/library/audience-users/interfaces/audience-users-action.action-payload.interface';
import { AudienceUserExtended } from 'app/library/audience-users/models/audience-user.extended.model';
import { IFetchEntityFailureActionPayload } from 'app/projects/entity/src/lib/interfaces/fetch-entity-failure-payload.interface';
import { IUpdateEntityFailureActionPayload } from 'app/projects/entity/src/lib/interfaces/update-entity-failure-payload.interface';
import { AudienceUser } from '../models/audience-user.model';

export class SetAudienceUser {
    static readonly type = '[AudienceUser] SetAudienceUser';

    constructor(public payload: AudienceUser) {}
}

export class UnsetAudienceUser {
    static readonly type = '[AudienceUser] UnsetAudienceUser';
}

export class FetchAudienceUser {
    static readonly type = '[AudienceUser] FetchAudienceUser';

    constructor(public payload: string) {}
}

export class FetchAudienceUserSuccess {
    static readonly type = '[AudienceUser] FetchAudienceUserSuccess';

    constructor(public payload: AudienceUser) {}
}

export class FetchAudienceUserFailure {
    static readonly type = '[AudienceUser] FetchAudienceUserFailure';

    constructor(public payload: IFetchEntityFailureActionPayload) {}
}

export class UpdateAudienceUser {
    static readonly type = '[AudienceUser] UpdateAudienceUser';

    constructor(public payload: AudienceUser) {}
}

export class UpdateAudienceUserSuccess {
    static readonly type = '[AudienceUser] UpdateAudienceUserSuccess';

    constructor(public payload: AudienceUser) {}
}

export class UpdateAudienceUserFailure {
    static readonly type = '[AudienceUser] UpdateAudienceUserFailure';

    constructor(public payload: IUpdateEntityFailureActionPayload) {}
}
export class AddUsersToAudience {
    static readonly type = '[AudienceUser] AddUsersToAudience';

    constructor(public payload: IManageAudienceUserActionPayload) {}
}

export class AddUsersToAudienceSuccess {
    static readonly type = '[AudienceUser] AddUsersToAudienceSuccess';

    constructor(public payload: { newlyAssigned: AudienceUserExtended[]; alreadyInChildren: AudienceUserExtended[]; alreadyAssigned: AudienceUserExtended[] }) {}
}

export class AddUsersToAudienceFailure {
    static readonly type = '[AudienceUser] AddUsersToAudienceFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}

export class AssignUserToAudience {
    static readonly type = '[AudienceUser] AssignUserToAudience';

    constructor(public payload: IManageAudienceUserActionPayload) {}
}

export class AssignUserToAudienceSuccess {
    static readonly type = '[AudienceUser] AssignUserToAudienceSuccess';

    constructor(public payload: { newlyAssigned: AudienceUserExtended[]; alreadyInChildren: AudienceUserExtended[]; alreadyAssigned: AudienceUserExtended[] }) {}
}

export class AssignUserToAudienceFailure {
    static readonly type = '[AudienceUser] AssignUserToAudienceFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}

export class DeleteAudienceUsers {
    static readonly type = '[AudienceUser] DeleteAudienceUsers';

    constructor(public payload: { audienceUsers: [{ id: string }] }) {}
}

export class DeleteAudienceUsersSuccess {
    static readonly type = '[AudienceUser] DeleteAudienceUsersSuccess';

    constructor(public payload: string) {}
}

export class DeleteAudienceUsersFailure {
    static readonly type = '[AudienceUser] DeleteAudienceUsersFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}

export class MoveAudienceUsers {
    static readonly type = '[AudienceUser] MoveAudienceUser';

    constructor(public payload: IManageAudienceUsersActionPayload) {}
}

export class MoveAudienceUsersSuccess {
    static readonly type = '[AudienceUser] MoveAudienceUsersSuccess';

    constructor(public payload: string) {}
}

export class MoveAudienceUsersFailure {
    static readonly type = '[AudienceUser] MoveAudienceUsersFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}

export class FetchAudienceByUserId {
    static readonly type = '[AudienceUser] FetchAudienceByUserId';

    constructor(
        public payload: {
            userId: string;
            pageSize?: number;
            pageIndex?: number;
        }
    ) {}
}

export class FetchAudienceByUserIdSuccess {
    static readonly type = '[AudienceUser] FetchAudienceByUserIdSuccess';

    constructor(public payload: AudienceUser[]) {}
}

export class FetchAudienceByUserIdFailure {
    static readonly type = '[AudienceUser] FetchAudienceByUserIdFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}
